var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showCustomLayerSymbologyDialog,
        "max-width": "600px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Customize Layer Symbology")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("custom-symbology-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "auto",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _vm.layer.service_type !== "L"
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "validation-observer",
                            { ref: "gisDataFieldForm" },
                            [
                              _c(
                                "form",
                                { staticStyle: { width: "33%" } },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Display features using",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-select", {
                                                attrs: {
                                                  autocomplete: "off",
                                                  label:
                                                    "Display features using",
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "displayFeaturesUsing",
                                                  items:
                                                    _vm.filteredDisplayFeaturesChoices,
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                },
                                                model: {
                                                  value:
                                                    _vm.displayFeaturesUsing,
                                                  callback: function ($$v) {
                                                    _vm.displayFeaturesUsing =
                                                      $$v
                                                  },
                                                  expression:
                                                    "displayFeaturesUsing",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1796790645
                                    ),
                                  }),
                                  _vm.displayFeaturesUsing === "unique_value"
                                    ? _c("validation-provider", {
                                        attrs: {
                                          name: "Choose a field to display",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      autocomplete: "off",
                                                      label:
                                                        "Choose a field to display",
                                                      "hide-details": "auto",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      color: "primary",
                                                      name: "fieldToDisplay",
                                                      items:
                                                        _vm.fieldToReferenceChoices,
                                                      "item-text": "label",
                                                      "item-value": "label",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fieldToReference,
                                                      callback: function ($$v) {
                                                        _vm.fieldToReference =
                                                          $$v
                                                      },
                                                      expression:
                                                        "fieldToReference",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3593820067
                                        ),
                                      })
                                    : _vm.displayFeaturesUsing ===
                                        "class_break" &&
                                      _vm.layer.service_type !== "L"
                                    ? _c("validation-provider", {
                                        attrs: {
                                          name: "Choose a field to reference",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ errors, valid }) {
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      autocomplete: "off",
                                                      label:
                                                        "Choose a field to reference",
                                                      "hide-details": "auto",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      color: "primary",
                                                      name: "fieldToReference",
                                                      items:
                                                        _vm.fieldToReferenceChoices,
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fieldToReference,
                                                      callback: function ($$v) {
                                                        _vm.fieldToReference =
                                                          $$v
                                                      },
                                                      expression:
                                                        "fieldToReference",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          977538958
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.displayFeaturesUsing === "class_break"
                                ? _c("v-switch", {
                                    attrs: {
                                      label:
                                        "Convert Date to Days From Current",
                                    },
                                    model: {
                                      value: _vm.convertDateToDateFromCurrent,
                                      callback: function ($$v) {
                                        _vm.convertDateToDateFromCurrent = $$v
                                      },
                                      expression:
                                        "convertDateToDateFromCurrent",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("SymbolsForm", {
                attrs: {
                  mapServiceId: _vm.mapServiceId,
                  rendererId: _vm.rendererId,
                  displayFeaturesUsing: _vm.displayFeaturesUsing,
                  fieldToReference: _vm.fieldToReference,
                },
                on: {
                  "renderer-symbols-changed": function ($event) {
                    _vm.rendererSymbols = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: { click: _vm.saveSymbology },
                },
                [_vm._v(" Save Symbology ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }