var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Layers" } }),
          _c(
            "PageLayout",
            [
              _c("v-data-table", {
                staticClass: "cursor-pointer",
                staticStyle: { "overflow-x": "hidden !important" },
                attrs: {
                  headers: _vm.headers,
                  items: _vm.filteredLayers,
                  "hide-default-footer": "",
                  search: _vm.search,
                  "custom-filter": _vm.filterLayer,
                  "disable-pagination": "",
                  "item-key": "map_service_id",
                  "single-select": "",
                },
                on: { "click:row": _vm.rowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-end gap px-2",
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Search",
                                        "hide-details": "auto",
                                        color: "primary",
                                        name: "search",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c("v-icon", [
                                                _vm._v(_vm._s(_vm.mdiMagnify)),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.search,
                                        callback: function ($$v) {
                                          _vm.search = $$v
                                        },
                                        expression: "search",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              id: "addLayerBtn",
                                              disabled: !_vm.canManageLayers,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showCreateLayerDialog = true
                                              },
                                            },
                                          },
                                          [_vm._v(" + Layer ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "px-3 pt-0 pb-2 my-0 d-flex justify-start",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "d-flex",
                                      on: {
                                        click: function ($event) {
                                          _vm.showActiveLayers =
                                            !_vm.showActiveLayers
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "chip overflow-hidden text-truncate my-0 py-0 cursor-pointer",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.showActiveLayers
                                                  ? "Active"
                                                  : "Archived"
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiSyncCircle) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.service_name`,
                      fn: function ({ item }) {
                        return [
                          item.children
                            ? _c(
                                "div",
                                { staticClass: "py-3" },
                                [
                                  _c("div", { staticClass: "py-1" }, [
                                    _vm._v(_vm._s(item.service_name)),
                                  ]),
                                  _vm._l(item.children, function (child) {
                                    return _c(
                                      "div",
                                      {
                                        key: child.map_service_id,
                                        staticClass: "d-flex pl-2 py-0",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "mr-2 mt-n3" },
                                          [_vm._v(_vm._s(_vm.mdiAlphaL))]
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(child.service_name)),
                                        ]),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            : _c("div", [_vm._v(_vm._s(item.service_name))]),
                        ]
                      },
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("mdi-dots-vertical")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              item.is_active
                                ? _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openEditLayerDialog(
                                                item,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiPencil) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Edit Layer "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            disabled: !_vm.canManageLayers,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateLayer(
                                                item,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mdiArchiveArrowDown
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Archive Layer "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            disabled: !_vm.canManageLayers,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showConfirmDeleteLayerDialog = true
                                              _vm.layerToDelete =
                                                item.map_service_id
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDelete) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Delete Layer "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateLayer(item, true)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mdiArchiveArrowUp
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" Make Active "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.activeLayer,
                  callback: function ($$v) {
                    _vm.activeLayer = $$v
                  },
                  expression: "activeLayer",
                },
              }),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "650px",
                    persistent: "",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showCreateLayerDialog,
                    callback: function ($$v) {
                      _vm.showCreateLayerDialog = $$v
                    },
                    expression: "showCreateLayerDialog",
                  },
                },
                [
                  _vm.showCreateLayerDialog
                    ? _c("CreateLayerForm", {
                        on: {
                          "create-layer-form-close": function ($event) {
                            _vm.showCreateLayerDialog = false
                            _vm.getLayers()
                          },
                          "create-layer-form-submitted": function ($event) {
                            _vm.showCreateLayerDialog = false
                            _vm.getLayers()
                          },
                          "layer-not-created": function ($event) {
                            _vm.showCreateLayerDialog = false
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600px",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showConfirmDeleteLayerDialog,
                    callback: function ($$v) {
                      _vm.showConfirmDeleteLayerDialog = $$v
                    },
                    expression: "showConfirmDeleteLayerDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-content", { staticClass: "pl-3" }, [
                            _c("div", { staticClass: "text-h5 py-3" }, [
                              _vm._v("Delete Layer?"),
                            ]),
                            _vm._v(
                              " Are you sure you want to delete this layer? This action cannot be undone. "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-content", [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showConfirmDeleteLayerDialog = false
                                        _vm.layerToDelete = undefined
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteLayer(
                                          _vm.layerToDelete
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Delete Layer ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  staticStyle: { "background-color": "#f1f2f1" },
                  attrs: {
                    "max-width": "600px",
                    persistent: "",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showEditLayerDialog,
                    callback: function ($$v) {
                      _vm.showEditLayerDialog = $$v
                    },
                    expression: "showEditLayerDialog",
                  },
                },
                [
                  _vm.showEditLayerDialog
                    ? _c("EditLayerForm", {
                        attrs: { selectedLayer: _vm.selectedLayer },
                        on: {
                          "edit-layer-form-close": function ($event) {
                            _vm.showEditLayerDialog = false
                            _vm.resetURL()
                            _vm.getLayers()
                          },
                          "edit-layer-form-submitted": function ($event) {
                            _vm.showEditLayerDialog = false
                            _vm.resetURL()
                            _vm.getLayers()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("LayerCannotBeDeletedDialog", {
                attrs: {
                  showLayerCannotBeDeletedDialog:
                    _vm.showLayerCannotBeDeletedDialog,
                },
                on: {
                  cancel: function ($event) {
                    _vm.showLayerCannotBeDeletedDialog = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }