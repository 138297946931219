var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "elevation-2 mb-3",
          attrs: { fab: "", color: "primary", small: "" },
        },
        [_c("img", { attrs: { src: require("@/assets/UtiliBot_Face.svg") } })]
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showStep1,
                  expression: "showStep1",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Hi! I'm the Create Layer UtiliBot and I will guide you through the process of creating a new layer. Let's begin. "
              ),
            ]
          ),
        ],
        1
      ),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showStep2,
                expression: "showStep2",
              },
            ],
          },
          [
            !_vm.viewLayerTypeDescriptions
              ? _c(
                  "v-alert",
                  {
                    staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                    attrs: { color: "primary", dark: "", width: "fit-content" },
                  },
                  [_vm._v(" What is the layer type? ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.showStep3
        ? _c("v-expand-transition", [
            _c("div", [
              _c(
                "div",
                { staticClass: "d-flex justify-end" },
                [
                  !_vm.selectedServiceTypeLabel
                    ? _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showLayerTypeDescriptionsDialog = true
                            },
                          },
                        },
                        [_vm._v(" View Layer Type Descriptions ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-end mb-2" },
                [
                  !_vm.selectedServiceTypeLabel
                    ? _c(
                        "v-list",
                        {
                          staticClass: "py-0",
                          style: {
                            width: "fit-content",
                            "border-radius": "1%",
                            border: "1px solid primary",
                          },
                          attrs: { dense: "" },
                        },
                        _vm._l(_vm.serviceTypeOptions, function (s, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: s.value,
                              staticClass: "my-0 py-0",
                              style: {
                                "border-bottom":
                                  i !== _vm.serviceTypeOptions.length - 1
                                    ? "1px solid primary"
                                    : "",
                                "background-color":
                                  _vm.layer.service_type === s.value
                                    ? "primary"
                                    : "white",
                              },
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onStep1Click(s.value)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  style: {
                                    color:
                                      _vm.layer.service_type === s.value
                                        ? "white"
                                        : "primary",
                                  },
                                },
                                [_vm._v(" " + _vm._s(s.label) + " ")]
                              ),
                            ]
                          )
                        }),
                        1
                      )
                    : _c(
                        "v-list",
                        {
                          attrs: {
                            outlined: "",
                            rounded: "",
                            width: "fit-content",
                          },
                        },
                        [
                          _c("v-list-item", [
                            _vm._v(
                              " " + _vm._s(_vm.selectedServiceTypeLabel) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("LayerTypeDescriptionsDialog", {
        attrs: {
          showLayerTypeDescriptionsDialog: _vm.showLayerTypeDescriptionsDialog,
        },
        on: {
          "layer-type-descriptions-dialog-close": function ($event) {
            _vm.showLayerTypeDescriptionsDialog = false
          },
        },
      }),
      ["FS", "F", "S"].includes(_vm.layer.service_type)
        ? [
            _c(
              "v-alert",
              {
                staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                attrs: { color: "primary", dark: "", width: "fit-content" },
              },
              [
                !_vm.hasEsriToken
                  ? [_vm._v("Enter the service URL.")]
                  : [_vm._v("Enter the service URL or")],
                _vm.hasEsriToken
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          color: "secondary",
                          disabled: _vm.layer.service_url !== "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showBrowseArcGIS = true
                          },
                        },
                      },
                      [_vm._v(" Browse ArcGIS Services ")]
                    )
                  : _vm._e(),
                _vm.showBrowseArcGIS
                  ? _c("BrowseArcGISServices", {
                      attrs: { showBrowseArcGIS: _vm.showBrowseArcGIS },
                      on: {
                        "browse-arcgis-services-close": function ($event) {
                          _vm.showBrowseArcGIS = false
                        },
                        "browse-arcgis-services-submitted": function ($event) {
                          _vm.showBrowseArcGIS = false
                          _vm.importingServices = true
                          _vm.serviceUrlSubmitted = true
                          _vm.$emit("browse-arcgis-services-submitted", $event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
            !_vm.importingServices
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.showSupportedUrlExampleDialog = true
                          },
                        },
                      },
                      [_vm._v(" Supported URL Examples ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("SupportedUrlExampleDialog", {
              attrs: {
                showSupportedUrlExampleDialog:
                  _vm.showSupportedUrlExampleDialog,
                serviceType: _vm.layer.service_type,
              },
              on: {
                "supported-url-dialog-close": function ($event) {
                  _vm.showSupportedUrlExampleDialog = false
                },
              },
            }),
            ["FS", "F", "S"].includes(_vm.layer.service_type)
              ? _c("div", [
                  !_vm.importingServices && !_vm.manualInput
                    ? _c(
                        "div",
                        [
                          _c("validation-observer", { ref: "serviceUrlForm" }, [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Service URL",
                                    rules: {
                                      required: true,
                                      regex:
                                        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                                      ends_with_regex: true,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-textarea", {
                                              attrs: {
                                                outlined: "",
                                                color: "#E5E5E5",
                                                "hide-details": "auto",
                                                name: "serviceUrl",
                                                "background-color": "white",
                                                "error-messages": errors,
                                                success: valid,
                                                "full-width": "",
                                                "auto-grow": "",
                                                rows: "1",
                                              },
                                              on: { keypress: _vm.onKeyPress },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "append",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-n2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  type: "submit",
                                                                  color:
                                                                    "primary",
                                                                  disabled:
                                                                    !valid,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.testConnection()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.mdiSend
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.layer.service_url,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.layer,
                                                    "service_url",
                                                    $$v
                                                  )
                                                },
                                                expression: "layer.service_url",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2480186682
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm.importingServices && !_vm.manualInput
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-list",
                            {
                              attrs: {
                                outlined: "",
                                rounded: "",
                                width: "fit-content",
                                dense: "",
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { staticStyle: { "word-break": "break-all" } },
                                [_vm._v(" Import Layers ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-list",
                            {
                              attrs: {
                                outlined: "",
                                rounded: "",
                                width: "85%",
                                dense: "",
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { staticStyle: { "word-break": "break-all" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.layer.service_url) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
          ]
        : ["W"].includes(_vm.layer.service_type)
        ? [
            _c(
              "v-alert",
              {
                staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                attrs: { color: "primary", dark: "", width: "fit-content" },
              },
              [_vm._v(" Enter the service URL. ")]
            ),
            !_vm.serviceUrlSubmitted
              ? _c("validation-observer", { ref: "serviceUrlForm" }, [
                  _c(
                    "form",
                    {
                      staticClass: "my-2",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "Service URL",
                          rules: {
                            required: true,
                            regex:
                              /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      color: "#E5E5E5",
                                      "hide-details": "auto",
                                      name: "serviceUrl",
                                      "background-color": "white",
                                      "error-messages": errors,
                                      success: valid,
                                      "full-width": "",
                                      "auto-grow": "",
                                      rows: "1",
                                    },
                                    on: { keypress: _vm.onKeyPress },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "mt-n2" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        type: "submit",
                                                        color: "primary",
                                                        disabled: !valid,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.testWmsLayerUrlConnection()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiSend
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "append-outer",
                                          fn: function () {
                                            return [
                                              _vm.testingLayer
                                                ? _c("v-progress-circular", {
                                                    attrs: {
                                                      size: 20,
                                                      indeterminate: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.layer.service_url,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.layer,
                                          "service_url",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "layer.service_url",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3097035970
                        ),
                      }),
                    ],
                    1
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-list",
                      {
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "85%",
                          dense: "",
                        },
                      },
                      [
                        _c(
                          "v-list-item",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(" " + _vm._s(_vm.layer.service_url) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]
        : _vm._e(),
      _vm.layer.service_type === "L"
        ? _c(
            "div",
            [
              _c("v-expand-transition", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.layer.service_type === "L",
                        expression: "layer.service_type === 'L'",
                      },
                    ],
                  },
                  [
                    _c(
                      "v-alert",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.accounts811.length > 1,
                            expression: "accounts811.length > 1",
                          },
                        ],
                        staticClass:
                          "rounded-tl-lg rounded-tr-lg rounded-br-lg d-flex justify-space-between",
                        attrs: { color: "primary", dark: "", width: "85%" },
                      },
                      [
                        _vm._v(
                          " It looks like you have more than one 811 Code in your organization. What 811 Code should be displayed on this layer? "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.accounts811.length > 1 && _vm.layer.service_type === "L"
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-end mb-5" },
                    [
                      _vm.selected811AccountLabel === undefined
                        ? _c(
                            "v-list",
                            {
                              staticClass: "py-0",
                              style: {
                                width: "fit-content",
                                "border-radius": "1%",
                                border: "1px solid primary",
                              },
                              attrs: { dense: "" },
                            },
                            _vm._l(_vm.account811Choices, function (a, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: a.value,
                                  staticClass: "my-0 py-0",
                                  style: {
                                    "border-bottom":
                                      i !== _vm.account811Choices.length - 1
                                        ? "1px solid primary"
                                        : "",
                                    "background-color":
                                      _vm.layer
                                        .locate_request_provider_account_id ===
                                      a.value
                                        ? "primary"
                                        : "white",
                                  },
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.layer.locate_request_provider_account_id =
                                        a.value
                                      _vm.$emit("layer-saved-step-2", _vm.layer)
                                      _vm.$emit("layer-saved-step-3", _vm.layer)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        color:
                                          _vm.layer
                                            .locate_request_provider_account_id ===
                                          a.value
                                            ? "white"
                                            : "primary",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(a.label) + " ")]
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _c(
                            "v-list",
                            {
                              attrs: {
                                outlined: "",
                                rounded: "",
                                width: "fit-content",
                                dense: "",
                              },
                            },
                            [
                              _c("v-list-item", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.selected811AccountLabel) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCreateLayerAnywayMessage,
                expression: "showCreateLayerAnywayMessage",
              },
            ],
          },
          [
            _c(
              "v-alert",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCreateLayerAnywayMessage,
                    expression: "showCreateLayerAnywayMessage",
                  },
                ],
                staticClass:
                  "mt-5 rounded-tl-lg rounded-tr-lg rounded-br-lg d-flex justify-space-between",
                attrs: { color: "primary", dark: "", width: "85%" },
              },
              [
                _vm._v(
                  " Testing the connection to the layer has failed. Do you want to create the layer anyway? "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.showCreateLayerAnywayMessage
        ? _c(
            "div",
            { staticClass: "d-flex justify-end mb-5" },
            [
              _vm.createLayerAnyway === undefined
                ? _c(
                    "v-list",
                    {
                      staticClass: "py-0",
                      style: {
                        width: "fit-content",
                        "border-radius": "1%",
                        border: "1px solid primary",
                      },
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "my-0 py-0",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.createLayerAnyway = "Yes"
                              _vm.onStep2Click()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.createLayerAnyway === "Yes"
                                    ? "white"
                                    : "primary",
                              },
                            },
                            [_vm._v(" Yes ")]
                          ),
                        ]
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "my-0 py-0",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.createLayerAnyway = "No"
                              _vm.showLayerNotCreated()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.createLayerAnyway === "No"
                                    ? "white"
                                    : "primary",
                              },
                            },
                            [_vm._v(" No ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-list",
                    {
                      attrs: {
                        outlined: "",
                        rounded: "",
                        width: "fit-content",
                        dense: "",
                      },
                    },
                    [
                      _c("v-list-item", [
                        _vm._v(" " + _vm._s(_vm.createLayerAnyway) + " "),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.createLayerAnyway === "No",
                expression: "createLayerAnyway === 'No'",
              },
            ],
          },
          [
            _c(
              "v-alert",
              {
                staticClass: "mt-5 rounded-tl-lg rounded-tr-lg rounded-br-lg",
                attrs: { color: "primary", dark: "", width: "85%" },
              },
              [_vm._v(" The layer was not created. ")]
            ),
          ],
          1
        ),
      ]),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedServiceTypeLabel && _vm.serviceUrlSubmitted,
                expression: "selectedServiceTypeLabel && serviceUrlSubmitted",
              },
            ],
          },
          [
            _c(
              "v-alert",
              {
                staticClass: "mt-5 rounded-tl-lg rounded-tr-lg rounded-br-lg",
                attrs: { color: "primary", dark: "", width: "85%" },
              },
              [_vm._v(" Great. I will create the layer now... ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }