var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "mx-auto",
      attrs: { value: _vm.showExitLayerCreationDialog, "max-width": "550px" },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-content", { staticClass: "pl-3" }, [
                _c("div", { staticClass: "text-h5 py-3" }, [
                  _vm._v("Exit Layer Creation UtiliBot?"),
                ]),
                _vm._v(
                  " If you exit the Layer Creation UtiliBot now you will lose the progress you've made in the creation of this layer. Are you sure you want to exit? "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("exit-layer-creation-dialog-close")
                    },
                  },
                },
                [_vm._v(" Return to Utilibot ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.$emit("create-layer-form-close")
                      _vm.deleteLayer()
                    },
                  },
                },
                [_vm._v(" Exit Utilibot ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }