<template>
  <div class="mt-2">
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        v-show="showAlert1"
      >
        Do you want to add UtiliSync Fields to the layer?
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <div class="d-flex justify-end" v-show="showAlert1">
        <v-btn
          text
          color="primary"
          @click="showUtiliSyncFieldDescriptionDialog = true"
        >
          View UtiliSync Field Description
        </v-btn>
      </div>
    </v-expand-transition>

    <UtiliSyncFieldDescriptionDialog
      :showUtiliSyncFieldDescriptionDialog="showUtiliSyncFieldDescriptionDialog"
      @utilisync-field-descriptions-dialog-close="
        showUtiliSyncFieldDescriptionDialog = false
      "
    />

    <div class="d-flex justify-end mb-5">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
        v-if="!selectedChoice"
      >
        <v-list-item
          v-for="(c, i) of computedChoices"
          :key="c.label"
          :style="{
            'border-bottom':
              i !== computedChoices.length - 1 ? '1px solid primary' : '',
            'background-color': 'white',
          }"
          class="my-0 py-0"
          @click="onListItemClick(c.label)"
        >
          <span :style="{ color: 'primary' }">
            {{ c.label }}
          </span>
        </v-list-item>
      </v-list>
      <v-list v-else outlined rounded width="fit-content">
        <v-list-item>
          {{ selectedChoice }}
        </v-list-item>
      </v-list>
    </div>

    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        v-show="skipStep"
      >
        That's ok. Remember, you can always add UtiliSync Fields later!
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        v-show="showOpenFieldEditorMessage"
      >
        Great. I'll open the UtiliSync Fields editor now.
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        v-show="closedAddUtiliSyncFieldDialog"
      >
        <template v-if="numUtiliSyncFields > 0">
          You added {{ numUtiliSyncFields }} UtiliSync Fields. Remember you can
          always add/edit UtiliSync fields later.
        </template>
        <template v-else>
          It looks like you didn't add any UtiliSync Fields. You can always add
          UtiliSync Fields later.
        </template>
      </v-alert>
    </v-expand-transition>

    <UtiliSyncFieldsDialog
      :mapServiceId="mapServiceId"
      :showUtiliSyncFieldsDialog="showUtiliSyncFieldsDialog"
      @add-utilisync-fields-dialog-close="
        showUtiliSyncFieldsDialog = false;
        closedAddUtiliSyncFieldDialog = true;
        $emit('layer-saved', layer);
      "
      @get-gis-data-fields="numUtiliSyncFields = $event"
      v-if="showUtiliSyncFieldsDialog"
    />
  </div>
</template>

<script>
import UtiliSyncFieldsDialog from "@/components/layers/create-layer-form/step-3-add-utilisync-fields/UtiliSyncFieldsDialog";
import UtiliSyncFieldDescriptionDialog from "@/components/layers/create-layer-form/step-3-add-utilisync-fields/UtiliSyncFieldDescriptionDialog";
import { cloneDeep } from "lodash";
import sleep from "@/mixins/sleep";

const choices = [{ label: "Add UtiliSync Fields" }, { label: "Skip For Now" }];

export default {
  name: "Step4AddUtiliSyncFields",
  components: {
    UtiliSyncFieldsDialog,
    UtiliSyncFieldDescriptionDialog,
  },
  props: {
    layerObj: Object,
    mapServiceId: String,
  },
  data() {
    return {
      choices,
      showUtiliSyncFieldsDialog: false,
      skipStep: false,
      showOpenFieldEditorMessage: false,
      closedAddUtiliSyncFieldDialog: false,
      numUtiliSyncFields: 0,
      layer: {},
      showAlert1: false,
      selectedChoice: "",
      showUtiliSyncFieldDescriptionDialog: false,
    };
  },
  methods: {
    onListItemClick(label) {
      this.selectedChoice = label;
      if (label === "Add UtiliSync Fields") {
        this.showOpenFieldEditorMessage = true;
        this.showUtiliSyncFieldsDialog = true;
      } else if (label === "Skip For Now") {
        this.skipStep = true;
        this.$emit("layer-saved", this.layer);
      }
    },
  },
  computed: {
    computedChoices() {
      if (this.skipStep) {
        return [{ label: "Skip For Now" }];
      }
      if (this.showOpenFieldEditorMessage) {
        return [{ label: "Add UtiliSync Fields" }];
      }
      return this.choices;
    },
  },
  async beforeMount() {
    this.layer = cloneDeep(this.layerObj);
    await sleep(500);
    this.showAlert1 = true;
  },
  watch: {
    layerObj: {
      deep: true,
      handler(val) {
        this.layer = {
          ...this.layer,
          ...val,
        };
      },
    },
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 8px !important;
}
</style>
