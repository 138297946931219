var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showLayerTypeDescriptionsDialog,
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Layer Type Descriptions")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("layer-type-descriptions-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              staticStyle: { "background-color": "#f1f2f1" },
            },
            [
              _c("p", [
                _c("b", [_vm._v("UtiliSync Layer")]),
                _vm._v(
                  " - A point layer that lives inside of UtiliSync. Great for sites. Cannot be shared with ArcGIS. "
                ),
              ]),
              _c("p", [
                _c("b", [_vm._v("811 Ticket Layer")]),
                _vm._v(" - Contains 811 tickets."),
              ]),
              _c("p", [
                _c("b", [_vm._v("ArcGIS Feature Service")]),
                _vm._v(
                  " - ArcGIS layer where URL ends with “../FeatureServer/<id>”. Forms can be attached to this type layer type. "
                ),
              ]),
              _c("p", [
                _c("b", [_vm._v("ArcGIS Map Service")]),
                _vm._v(
                  " - ArcGIS layer where URL ends with “../MapServer”. Forms cannot be attached to this type of layer. "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }