<template>
  <v-dialog
    :value="showLayerTypeDescriptionsDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Layer Type Descriptions</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('layer-type-descriptions-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5" style="background-color: #f1f2f1">
        <p>
          <b>UtiliSync Layer</b> - A point layer that lives inside of UtiliSync.
          Great for sites. Cannot be shared with ArcGIS.
        </p>

        <p><b>811 Ticket Layer</b> - Contains 811 tickets.</p>

        <p>
          <b>ArcGIS Feature Service</b> - ArcGIS layer where URL ends with
          “../FeatureServer/&lt;id&gt;”. Forms can be attached to this type
          layer type.
        </p>

        <p>
          <b>ArcGIS Map Service</b> - ArcGIS layer where URL ends with
          “../MapServer”. Forms cannot be attached to this type of layer.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LayerTypeDescriptionsDialog",
  props: {
    showLayerTypeDescriptionsDialog: Boolean,
  },
};
</script>
