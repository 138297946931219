<template>
  <div>
    <v-expand-transition>
      <v-alert
        v-show="showLayersToShowOnMap"
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
      >
        Layers to show on map:
      </v-alert>
    </v-expand-transition>

    <div class="d-flex justify-end mb-5" v-if="showLayersToShowOnMap">
      <section>
        <v-list
          :style="{
            width: 'fit-content',
            'border-radius': '1%',
            border: '1px solid primary',
          }"
          class="py-0"
          dense
        >
          <v-list-item class="d-flex align-center">
            <v-checkbox
              :input-value="
                selectedSublayerIds.length === sublayerChoices.length
              "
              @change="onSelectAllChange"
              dense
              hide-details
              class="py-0 my-0"
            >
              <template #label>
                <b>Select All</b>
              </template>
            </v-checkbox>
          </v-list-item>
          <v-list-item
            class="d-flex align-center"
            v-for="s of sublayerChoices"
            :key="s.name"
          >
            <v-checkbox
              v-model="selectedSublayerIds"
              :value="s.name"
              dense
              hide-details
              class="py-0 my-0"
              name="selectedSublayerIds"
            ></v-checkbox>
            <section>
              {{ s.title }}
            </section>
          </v-list-item>
        </v-list>

        <section class="py-2">
          <v-btn color="primary" @click="save()">Next</v-btn>
        </section>
      </section>
    </div>

    <v-expand-transition v-if="submitted">
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
      >
        Done! The "{{ layerObj.service_name }}" layer has been created
      </v-alert>
    </v-expand-transition>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "Step3WmsSubLayers",
  props: {
    layerObj: Object,
    wmsData: Object,
  },
  data() {
    return {
      showLayersToShowOnMap: false,
      sublayers: [],
      selectedSublayerIds: [],
      submitted: false,
    };
  },
  computed: {
    sublayerChoices() {
      return this.sublayers.map((s) => {
        const {
          Name: { _text: name },
          Title: { _text: title, _cdata: cData },
        } = s;
        return { name, title: title ?? cData };
      });
    },
  },
  mounted() {
    this.showLayersToShowOnMap = true;
  },
  beforeMount() {
    this.layer = cloneDeep(this.layerObj);
  },
  methods: {
    onSelectAllChange(checked) {
      if (checked) {
        this.selectedSublayerIds = this.sublayerChoices.map((s) => s.name);
      } else {
        this.selectedSublayerIds = [];
      }
    },
    getWmsSubLayers(layer) {
      const hasSublayers = layer.some((l) => Array.isArray(l?.Layer));
      if (!hasSublayers) {
        return layer;
      }
      return [layer, ...this.getWmsSubLayers(layer.map((l) => l.Layer).flat())]
        .flat()
        .filter((l) => l?.Name);
    },
    async getWmsLayerCapabilities() {
      const { wmsData } = this;
      if (!wmsData) {
        return;
      }

      try {
        if (
          Array.isArray(wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer)
        ) {
          this.sublayers = this.getWmsSubLayers(
            wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer
          );
          this.selectedSublayerIds = this.sublayers.filter(Boolean).map((s) => {
            const {
              Name: { _text: name },
            } = s;
            return name;
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    save() {
      this.layer.sub_layers = this.sublayerChoices.map((s) => {
        return { ...s, visible: this.selectedSublayerIds.includes(s.name) };
      });
      this.submitted = true;
      this.$emit("layer-saved", this.layer);
    },
  },
  watch: {
    wmsData: {
      deep: true,
      immediate: true,
      handler() {
        this.getWmsLayerCapabilities();
      },
    },
  },
};
</script>
