<template>
  <v-dialog
    :value="showCustomLayerSymbologyDialog"
    max-width="600px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" ref="toolbar" class="elevation-0">
        <v-toolbar-title>Customize Layer Symbology</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('custom-symbology-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-5"
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <v-card v-if="layer.service_type !== 'L'">
          <v-card-text>
            <validation-observer ref="gisDataFieldForm">
              <form style="width: 33%">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Display features using"
                  rules="required"
                >
                  <v-select
                    autocomplete="off"
                    label="Display features using"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="displayFeaturesUsing"
                    :items="filteredDisplayFeaturesChoices"
                    item-text="label"
                    item-value="value"
                    v-model="displayFeaturesUsing"
                  >
                  </v-select>
                </validation-provider>

                <validation-provider
                  v-if="displayFeaturesUsing === 'unique_value'"
                  v-slot="{ errors, valid }"
                  name="Choose a field to display"
                  rules="required"
                >
                  <v-select
                    autocomplete="off"
                    label="Choose a field to display"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="fieldToDisplay"
                    :items="fieldToReferenceChoices"
                    item-text="label"
                    item-value="label"
                    v-model="fieldToReference"
                  >
                  </v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Choose a field to reference"
                  rules="required"
                  v-else-if="
                    displayFeaturesUsing === 'class_break' &&
                    layer.service_type !== 'L'
                  "
                >
                  <v-select
                    autocomplete="off"
                    label="Choose a field to reference"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="fieldToReference"
                    :items="fieldToReferenceChoices"
                    item-text="label"
                    item-value="value"
                    v-model="fieldToReference"
                  >
                  </v-select>
                </validation-provider>
              </form>

              <v-switch
                v-if="displayFeaturesUsing === 'class_break'"
                v-model="convertDateToDateFromCurrent"
                label="Convert Date to Days From Current"
              >
              </v-switch>
            </validation-observer>
          </v-card-text>
        </v-card>

        <SymbolsForm
          :mapServiceId="mapServiceId"
          :rendererId="rendererId"
          :displayFeaturesUsing="displayFeaturesUsing"
          :fieldToReference="fieldToReference"
          @renderer-symbols-changed="rendererSymbols = $event"
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5" ref="cardAction">
        <v-btn color="primary" dark @click="saveSymbology">
          Save Symbology
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SymbolsForm from "@/components/layers/create-layer-form/step-5-symbology/custom-layer-symbology-dialog/SymbolsForm";
import axios from "axios";
import { cloneDeep } from "lodash";
import contentHeightMixin from "@/mixins/contentHeightMixin";

const APIURL = process.env.VUE_APP_API_URL;
const displayFeaturesChoices = [
  { label: "One Symbol", value: "simple" },
  { label: "Field Values", value: "unique_value" },
  { label: "Range of Values", value: "class_break" },
];

export default {
  name: "CustomLayerSymbologyDialog",
  props: {
    showCustomLayerSymbologyDialog: Boolean,
    mapServiceId: String,
    layerObj: Object,
  },
  components: {
    SymbolsForm,
  },
  mixins: [contentHeightMixin],
  computed: {
    fieldToReferenceChoices() {
      if (this.layer.service_type === "F") {
        return this.featureServiceGisFields
          .filter((f) => {
            const { type } = f;
            if (this.displayFeaturesUsing === "class_break") {
              return ["esriFieldTypeInteger", "esriFieldTypeDouble"].includes(
                type
              );
            }
            return true;
          })
          .map((f) => {
            const { name, alias } = f;
            return {
              label: alias || name,
              value: name,
            };
          });
      }
      return this.gisDataFields
        .filter((g) => {
          if (this.displayFeaturesUsing === "class_break") {
            return g.type === "number";
          }
          return g.gis_data_field_options.length > 0;
        })
        .map((g) => {
          const { name, gis_data_field_id: gisDataFieldId } = g;
          return {
            label: name,
            value: gisDataFieldId,
          };
        });
    },
    filteredDisplayFeaturesChoices() {
      if (this.layer.service_type === "L") {
        return [
          { label: "One Symbol", value: "simple" },
          { label: "Due Date", value: "class_break" },
        ];
      }
      return this.displayFeaturesChoices;
    },
  },
  async beforeMount() {
    this.layer = cloneDeep(this.layerObj);
    if (this.layer.service_type === "F") {
      this.displayFeaturesUsing = "class_break";
    }
    await this.getGisDataFields();
    await this.getFeatureServiceFields();
  },
  data() {
    return {
      renderer: {},
      displayFeaturesChoices,
      displayFeaturesUsing: "simple",
      fieldToReference: "",
      fieldToDisplay: "",
      rendererId: undefined,
      rendererSymbols: [],
      layer: {},
      gisDataFields: [],
      convertDateToDateFromCurrent: false,
      featureServiceGisFields: [],
    };
  },
  methods: {
    async getFeatureServiceFields() {
      const { service_url: featureServiceUrl } = this.layer;
      if (!featureServiceUrl) {
        return;
      }

      const params = {
        f: "pjson",
        token: localStorage.getItem("esri_token"),
      };
      const {
        data: { fields = [] },
      } = await axios.get(featureServiceUrl, { params });
      this.featureServiceGisFields = fields;
    },
    async getGisDataFields() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: mapServiceId,
        },
      });
      this.gisDataFields = results;
    },
    async updateRenderer() {
      const { rendererId, layer } = this;
      const {
        convertDateToDateFromCurrent,
        displayFeaturesUsing,
        fieldToReference,
      } = this;
      let rendererFields = {};
      if (layer.service_type === "L") {
        rendererFields = {
          type: "Point: Picture",
          convert_date_to_days: true,
          reference_field: "symVal",
          renderer_type: "class_break",
          apply_renderer_to_feature_service: true,
        };
      } else {
        rendererFields = {
          type: "",
          convert_date_to_days: convertDateToDateFromCurrent,
          reference_field: fieldToReference,
          renderer_type: displayFeaturesUsing,
          apply_renderer_to_feature_service: true,
        };
      }
      await axios.put(`${APIURL}/renderers/${rendererId}`, rendererFields);
    },
    async createRenderer() {
      const {
        mapServiceId,
        convertDateToDateFromCurrent,
        displayFeaturesUsing,
        fieldToReference,
      } = this;
      let rendererFields = {};
      if (this.layer.service_type === "L") {
        rendererFields = {
          type: "Point: Picture",
          convert_date_to_days: true,
          reference_field: "symVal",
          renderer_type: "class_break",
          apply_renderer_to_feature_service: true,
        };
      } else {
        rendererFields = {
          type: "",
          convert_date_to_days: convertDateToDateFromCurrent,
          reference_field: fieldToReference,
          renderer_type: displayFeaturesUsing,
          apply_renderer_to_feature_service: false,
        };
      }
      const {
        data: {
          results: {
            renderer: { renderer_id: rendererId },
          },
        },
      } = await axios.post(`${APIURL}/renderers`, {
        map_service_id: mapServiceId,
        type: "",
        ...rendererFields,
      });
      this.rendererId = rendererId;
    },
    async saveSymbology() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/renderer/map_service/${mapServiceId}`);
      this.rendererId = results?.renderer_id;
      this.renderer = { ...results };
      if (!this.rendererId) {
        await this.createRenderer();
      } else {
        await this.updateRenderer();
      }
      await axios.delete(`${APIURL}/renderer_symbol/all/${this.rendererId}`);
      await Promise.all(
        this.rendererSymbols.map((rs) => {
          const { rendererId } = this;
          const {
            fill_color: fillColor,
            label = null,
            max_value: maxValue,
            min_value: minValue,
            outline_color: outlineColor,
            picture_size: pictureSize,
            style,
            symbol_type: symbolType,
            url,
            width,
            gis_data_field_option_id: gisDataFieldOptionId,
          } = rs;
          return axios.post(`${APIURL}/renderer_symbol`, {
            fill_color: fillColor,
            label,
            max_value: maxValue,
            min_value: minValue,
            outline_color: outlineColor,
            picture_size: pictureSize,
            renderer_id: rendererId,
            style,
            symbol_type: symbolType === "shape" ? "image" : symbolType,
            url: symbolType === "basic" ? null : url,
            width,
            gis_data_field_option_id: gisDataFieldOptionId,
          });
        })
      );
      this.$emit("symbology-saved");
    },
  },
};
</script>
