var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAppIdDescDialog,
        "max-width": "400px",
        "retain-focus": false,
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(" App ID Description ")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("portal-app-id-desc-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "py-3" }, [
            _vm._v(
              " This is the App ID from an Application on ArcGIS Enterprise. "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://utilisync.helpscoutdocs.com/article/72-configure-arcgis-for-portal-to-allow-users-access-to-utilisync",
                  target: "_blank",
                },
              },
              [_vm._v(" Click here ")]
            ),
            _vm._v(" for detailed help article. "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }