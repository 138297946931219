<template>
  <v-dialog
    :value="showUtiliSyncFieldDescriptionDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>UtiliSync Field Description</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="$emit('utilisync-field-descriptions-dialog-close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-5 font-weight-bold"
        style="background-color: #f1f2f1"
      >
        <p>
          UtiliSync fields are used to store information about sites on the
          layer.
        </p>

        <p>Common UtiliSync fields include project name, address and status.</p>

        <p>You can always add/edit UtiliSync Fields later.</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UtiliSyncFieldDescriptionDialog",
  props: {
    showUtiliSyncFieldDescriptionDialog: Boolean,
  },
};
</script>
