var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAlert1,
                  expression: "showAlert1",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" Do you want to add UtiliSync Fields to the layer? ")]
          ),
        ],
        1
      ),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAlert1,
                expression: "showAlert1",
              },
            ],
            staticClass: "d-flex justify-end",
          },
          [
            _c(
              "v-btn",
              {
                attrs: { text: "", color: "primary" },
                on: {
                  click: function ($event) {
                    _vm.showUtiliSyncFieldDescriptionDialog = true
                  },
                },
              },
              [_vm._v(" View UtiliSync Field Description ")]
            ),
          ],
          1
        ),
      ]),
      _c("UtiliSyncFieldDescriptionDialog", {
        attrs: {
          showUtiliSyncFieldDescriptionDialog:
            _vm.showUtiliSyncFieldDescriptionDialog,
        },
        on: {
          "utilisync-field-descriptions-dialog-close": function ($event) {
            _vm.showUtiliSyncFieldDescriptionDialog = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-end mb-5" },
        [
          !_vm.selectedChoice
            ? _c(
                "v-list",
                {
                  staticClass: "py-0",
                  style: {
                    width: "fit-content",
                    "border-radius": "1%",
                    border: "1px solid primary",
                  },
                  attrs: { dense: "" },
                },
                _vm._l(_vm.computedChoices, function (c, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: c.label,
                      staticClass: "my-0 py-0",
                      style: {
                        "border-bottom":
                          i !== _vm.computedChoices.length - 1
                            ? "1px solid primary"
                            : "",
                        "background-color": "white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onListItemClick(c.label)
                        },
                      },
                    },
                    [
                      _c("span", { style: { color: "primary" } }, [
                        _vm._v(" " + _vm._s(c.label) + " "),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _c(
                "v-list",
                { attrs: { outlined: "", rounded: "", width: "fit-content" } },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.selectedChoice) + " "),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.skipStep,
                  expression: "skipStep",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " That's ok. Remember, you can always add UtiliSync Fields later! "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showOpenFieldEditorMessage,
                  expression: "showOpenFieldEditorMessage",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" Great. I'll open the UtiliSync Fields editor now. ")]
          ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.closedAddUtiliSyncFieldDialog,
                  expression: "closedAddUtiliSyncFieldDialog",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm.numUtiliSyncFields > 0
                ? [
                    _vm._v(
                      " You added " +
                        _vm._s(_vm.numUtiliSyncFields) +
                        " UtiliSync Fields. Remember you can always add/edit UtiliSync fields later. "
                    ),
                  ]
                : [
                    _vm._v(
                      " It looks like you didn't add any UtiliSync Fields. You can always add UtiliSync Fields later. "
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _vm.showUtiliSyncFieldsDialog
        ? _c("UtiliSyncFieldsDialog", {
            attrs: {
              mapServiceId: _vm.mapServiceId,
              showUtiliSyncFieldsDialog: _vm.showUtiliSyncFieldsDialog,
            },
            on: {
              "add-utilisync-fields-dialog-close": function ($event) {
                _vm.showUtiliSyncFieldsDialog = false
                _vm.closedAddUtiliSyncFieldDialog = true
                _vm.$emit("layer-saved", _vm.layer)
              },
              "get-gis-data-fields": function ($event) {
                _vm.numUtiliSyncFields = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }