var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showSupportedUrlExampleDialog,
        "max-width": "600px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c(
                "div",
                [
                  _c("v-toolbar-title", [
                    _vm._v("Service URL Formatting Example"),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("supported-url-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              staticStyle: { "background-color": "#f1f2f1" },
            },
            [
              _c("p", { staticClass: "font-weight-medium" }, [
                _vm._v("For Feature Services:"),
              ]),
              [
                _c("p", [
                  _vm._v(
                    " https://<server_name>/arcgis/rest/services/<layer_name>/FeatureServer "
                  ),
                ]),
                _c("p", [
                  _vm._v('Be sure the url ends with “/FeatureServer/<id>".'),
                ]),
              ],
              _c("br"),
              _c("p", { staticClass: "font-weight-medium" }, [
                _vm._v("For Map Services:"),
              ]),
              [
                _c("p", [
                  _vm._v(
                    " https://<server_name>/arcgis/rest/services/<layer_name>/MapServer "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Be sure the url ends with “/MapServer”. Do not include a layer ID (e.g. “/MapServer/3”). "
                  ),
                ]),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }