<template>
  <v-dialog
    :value="showReferenceFieldDescriptionDialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Reference Field Descriptions</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="$emit('reference-type-descriptions-dialog-close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5" style="background-color: #f1f2f1">
        <b
          >Reference Fields are an optional configuration used to name your GIS
          features.
        </b>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ReferenceFieldDescriptionDialog",
  props: {
    showReferenceFieldDescriptionDialog: Boolean,
  },
};
</script>
