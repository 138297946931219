<template>
  <div>
    <v-btn fab color="primary" class="elevation-2 mb-5" small>
      <img src="@/assets/UtiliBot-white.svg" />
    </v-btn>

    <v-expand-transition>
      <v-alert
        v-show="showWelcomeMsg"
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0 mb-5"
      >
        Hi! I'm the Create User UtiliBot and I will guide you through the
        process. Let's begin.
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-alert
        v-show="showFirstNameMsg"
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        What is the user's first name?
      </v-alert>
    </v-expand-transition>

    <div v-if="!firstNameSubmitted">
      <validation-observer ref="firstNameForm">
        <form @submit.prevent="saveFirstName">
          <validation-provider rules="required">
            <v-text-field
              outlined
              color="#E5E5E5"
              v-model="user.firstName"
              hide-details="auto"
              name="serviceName"
              single-line
              background-color="white"
              full-width
              ref="firstNameInput"
            >
              <template v-slot:append>
                <div class="mt-n2">
                  <v-btn
                    icon
                    type="submit"
                    color="primary"
                    :disabled="!user.firstName"
                  >
                    <v-icon>
                      {{ mdiSend }}
                    </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </form>
      </validation-observer>
    </div>
    <div v-else class="d-flex justify-end my-5">
      <v-list outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ user.firstName }}
        </v-list-item>
      </v-list>
    </div>

    <v-expand-transition>
      <v-alert
        v-show="showLastNameMsg"
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        What is the user's last name?
      </v-alert>
    </v-expand-transition>

    <div v-if="showLastNameMsg">
      <div v-if="!lastNameSubmitted">
        <validation-observer ref="lastNameForm">
          <form @submit.prevent="saveLastName">
            <validation-provider rules="required">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model="user.lastName"
                hide-details="auto"
                name="lastName"
                single-line
                background-color="white"
                full-width
                ref="lastNameInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="primary"
                      :disabled="!user.lastName"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list outlined rounded width="fit-content" dense>
          <v-list-item>
            {{ user.lastName }}
          </v-list-item>
        </v-list>
      </div>
    </div>

    <v-expand-transition>
      <v-alert
        v-show="showUserNameMsg"
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0"
      >
        Username for {{ user.firstName }}? It needs to be at least 6 characters
        long and can only have lowercase letters and numbers (no spaces or
        special characters).
      </v-alert>
    </v-expand-transition>

    <div v-if="showUserNameMsg">
      <div v-if="!userNameSubmitted">
        <validation-observer ref="userNameForm">
          <form @submit.prevent="saveUserName">
            <validation-provider :rules="{ required: true, regex: /^.{6,}$/ }">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model="user.username"
                hide-details="auto"
                name="username"
                single-line
                background-color="white"
                full-width
                @input="validateUsername"
                ref="usernameInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="primary"
                      :disabled="!usernameValid"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list outlined rounded width="fit-content" dense>
          <v-list-item>
            {{ user.username }}
          </v-list-item>
        </v-list>
      </div>
    </div>

    <RepeatUserNameForm
      v-for="t of showRepeatUsernameFormTimes"
      :key="t"
      :index="t"
      @step-1-finished="$emit('step-1-finished', { ...user, ...$event })"
      @show-repeat-username-form-again="showRepeatUsernameFormTimes++"
    />
  </div>
</template>

<script>
import sleep from "@/mixins/sleep";
import { mdiSend } from "@mdi/js";
import axios from "axios";
import RepeatUserNameForm from "@/components/organization/users/add-user-dialog/step-1-user-form/RepeatUserNameForm";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "Step1UserForm",
  data() {
    return {
      mdiSend,
      showWelcomeMsg: true,
      showFirstNameMsg: false,
      showLastNameMsg: false,
      showUserNameMsg: false,
      user: {
        firstName: "",
        lastName: "",
        username: "",
      },
      firstNameSubmitted: false,
      lastNameSubmitted: false,
      userNameSubmitted: false,
      showRepeatUsernameFormTimes: 0,
      usernameValid: false,
    };
  },
  components: {
    RepeatUserNameForm,
  },
  async beforeMount() {
    await sleep(1000);
    this.showFirstNameMsg = true;
    await this.$nextTick();
    this.$refs.firstNameInput.$refs.input.focus();
  },
  methods: {
    async validateUsername() {
      await this.$nextTick();
      this.usernameValid = await this.$refs.userNameForm.validate();
    },
    async saveFirstName() {
      const success = await this.$refs.firstNameForm.validate();
      if (!success) {
        return;
      }
      await sleep(250);
      this.firstNameSubmitted = true;
      this.showLastNameMsg = true;
      await this.$nextTick();
      this.$refs.lastNameInput.$refs.input.focus();
    },
    async saveLastName() {
      const success = await this.$refs.lastNameForm.validate();
      if (!success) {
        return;
      }
      await sleep(250);
      this.lastNameSubmitted = true;
      this.showUserNameMsg = true;
      await this.$nextTick();
      this.$refs.usernameInput.$refs.input.focus();
    },
    async saveUserName() {
      const success = await this.$refs.userNameForm.validate();
      if (!success) {
        return;
      }
      await sleep(250);
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users_by_username/${this.user.username}`);
      if (!results) {
        this.$emit("step-1-finished", this.user);
      } else {
        this.showRepeatUsernameFormTimes++;
      }
      this.userNameSubmitted = true;
    },
  },
};
</script>
