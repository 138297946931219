var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2" },
    [
      _c("validation-observer", { ref: "createLayerForm" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createLayer.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "v-toolbar",
              {
                ref: "toolbar",
                staticClass: "elevation-0",
                attrs: { dark: "", color: "primary" },
              },
              [
                _c("v-toolbar-title", [_vm._v("Create Layer")]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", dark: "" },
                    on: {
                      click: function ($event) {
                        return _vm.closeFormCheck()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-text",
              {
                ref: "layerBox",
                staticClass: "px-8",
                style: {
                  "background-color": "#f1f2f1",
                  height: _vm.$vuetify.breakpoint.xsOnly
                    ? `${_vm.contentHeight}px`
                    : "auto",
                  "overflow-y": "auto",
                  "min-height": _vm.$vuetify.breakpoint.xsOnly
                    ? undefined
                    : "60vh",
                  "max-height": _vm.$vuetify.breakpoint.xsOnly
                    ? undefined
                    : "60vh",
                },
              },
              [
                _c(
                  "div",
                  { ref: "layerInnerBox" },
                  [
                    _c("Step1LayerType", {
                      attrs: { layerObj: _vm.layer },
                      on: {
                        "layer-saved-step-1": function ($event) {
                          _vm.layer = $event
                        },
                        "layer-saved-step-2": function ($event) {
                          _vm.layer = $event
                        },
                        "layer-saved-step-3": function ($event) {
                          _vm.layer = $event
                          _vm.step1Complete = true
                        },
                        "layer-not-created": function ($event) {
                          return _vm.$emit("layer-not-created")
                        },
                        "browse-arcgis-services-submitted": function ($event) {
                          return _vm.importLayers($event)
                        },
                        "wms-data-requested": _vm.onWmsDataRequested,
                      },
                    }),
                    _c(
                      "div",
                      _vm._l(_vm.successfullyImportedLayers, function (layer) {
                        return _c(
                          "v-expand-transition",
                          { key: layer.map_service_id },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass:
                                  "rounded-tl-lg rounded-tr-lg rounded-br-lg mt-2",
                                attrs: {
                                  color: "primary",
                                  dark: "",
                                  width: "85%",
                                },
                              },
                              [
                                _vm._v(
                                  ' Layer "' +
                                    _vm._s(
                                      layer.type === "Feature Layer"
                                        ? layer.name
                                        : layer.title
                                    ) +
                                    '" was imported successfully. '
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm.massImportComplete === false
                      ? _c(
                          "div",
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "primary" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.errorMessages, function (errorMessage) {
                      return _c(
                        "div",
                        { key: _vm.errorMessages[errorMessage] },
                        [
                          _c(
                            "v-expand-transition",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "rounded-tl-lg rounded-tr-lg rounded-br-lg mt-2",
                                  attrs: {
                                    color: "primary",
                                    dark: "",
                                    width: "85%",
                                  },
                                },
                                [_vm._v(" " + _vm._s(errorMessage) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _c("Step2NameLayer", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.step1Complete,
                          expression: "step1Complete",
                        },
                      ],
                      attrs: { layerObj: _vm.layer, wmsData: _vm.wmsData },
                      on: { "layer-saved": _vm.onStep2Complete },
                    }),
                    _c("Step3WmsSubLayers", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.mapServiceId &&
                            _vm.step1Complete &&
                            _vm.step2Complete &&
                            ["W"].includes(_vm.layer.service_type),
                          expression:
                            "\n              mapServiceId &&\n              step1Complete &&\n              step2Complete &&\n              ['W'].includes(layer.service_type)\n            ",
                        },
                      ],
                      attrs: { layerObj: _vm.layer, wmsData: _vm.wmsData },
                      on: { "layer-saved": _vm.onStep3Complete },
                    }),
                    _c("Step4AddUtiliSyncFields", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.mapServiceId &&
                            _vm.step1Complete &&
                            _vm.step2Complete &&
                            _vm.step3Complete &&
                            ["U"].includes(_vm.layer.service_type),
                          expression:
                            "\n              mapServiceId &&\n              step1Complete &&\n              step2Complete &&\n              step3Complete &&\n              ['U'].includes(layer.service_type)\n            ",
                        },
                      ],
                      attrs: {
                        mapServiceId: _vm.mapServiceId,
                        layerObj: _vm.layer,
                      },
                      on: { "layer-saved": _vm.onStep4Complete },
                    }),
                    _vm.mapServiceId &&
                    _vm.step1Complete &&
                    _vm.step2Complete &&
                    _vm.step3Complete &&
                    _vm.step4Complete &&
                    _vm.gisDataFields &&
                    _vm.gisDataFields.length > 0 &&
                    ["U", "F", "W"].includes(_vm.layer.service_type)
                      ? _c("Step5AddReferenceField", {
                          attrs: {
                            mapServiceId: _vm.mapServiceId,
                            layerObj: _vm.layer,
                          },
                          on: { "layer-saved": _vm.onStep5Complete },
                        })
                      : _vm._e(),
                    _vm.mapServiceId &&
                    _vm.step1Complete &&
                    _vm.step2Complete &&
                    _vm.step3Complete &&
                    _vm.step4Complete &&
                    _vm.step5Complete &&
                    ["U"].includes(_vm.layer.service_type)
                      ? _c("Step6Symbology", {
                          attrs: {
                            layerObj: _vm.layer,
                            mapServiceId: _vm.mapServiceId,
                          },
                          on: { "layer-saved": _vm.onStep6Complete },
                        })
                      : _vm._e(),
                    _c(
                      "v-expand-transition",
                      [
                        _c(
                          "v-alert",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showCloseButton,
                                expression: "showCloseButton",
                              },
                            ],
                            staticClass:
                              "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                            attrs: { color: "primary", dark: "", width: "85%" },
                          },
                          [
                            _vm._v(
                              " Thanks for using the Create Layer UtiliBot to create your layer! "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end" },
                      [
                        _vm.showCloseButton
                          ? _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.$emit("create-layer-form-submitted")
                                    _vm.showCloseButton = false
                                  },
                                },
                              },
                              [_vm._v(" Close ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("ExitLayerCreationDialog", {
        attrs: {
          showExitLayerCreationDialog: _vm.showExitLayerCreationDialog,
          mapServiceId: _vm.mapServiceId,
        },
        on: {
          "exit-layer-creation-dialog-close": function ($event) {
            _vm.showExitLayerCreationDialog = false
          },
          "create-layer-form-close": function ($event) {
            _vm.showExitLayerCreationDialog = false
            _vm.$emit("create-layer-form-close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }