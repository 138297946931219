var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showUtiliSyncFieldsDialog,
        "max-width": "600px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              ref: "toolbar",
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("UtiliSync Fields")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add-utilisync-fields-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "auto",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _c("p", [
                _vm._v(
                  " UtiliSync fields are used to store information about site, such as project name, address and status. "
                ),
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showAddUtiliSyncFieldsDialog = true
                        },
                      },
                    },
                    [_vm._v(" + Add UtiliSync Field ")]
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-simple-table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "value" }, [
                              _c("p", { staticClass: "caption" }, [
                                _vm._v("Field Name"),
                              ]),
                            ]),
                            _c("th", [
                              _c("p", { staticClass: "caption" }, [
                                _vm._v("Type"),
                              ]),
                            ]),
                            _c("th", [
                              _c("p", { staticClass: "caption" }, [
                                _vm._v("Field Options"),
                              ]),
                            ]),
                            _c("th"),
                          ]),
                        ]),
                        _vm.gisDataFields.length > 0
                          ? _c(
                              "tbody",
                              _vm._l(
                                _vm.gisDataFields,
                                function (field, index) {
                                  return _c(
                                    "tr",
                                    { key: field.gis_data_field_id },
                                    [
                                      _c("td", { staticClass: "white" }, [
                                        _vm._v(_vm._s(field.name)),
                                      ]),
                                      _c("td", { staticClass: "white" }, [
                                        _vm._v(_vm._s(field.type)),
                                      ]),
                                      _c("td", { staticClass: "white" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              field.gis_data_field_options
                                                .length > 0
                                                ? "Yes"
                                                : "No"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "white d-flex justify-end align-center",
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mdiDotsVertical
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showEditUtiliSyncFieldDialog = true
                                                          _vm.selectedUtiliSyncField =
                                                            field
                                                          _vm.selectedUtiliSyncFieldIndex =
                                                            index
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "primary--text text--lighten-1",
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.mdiPencil
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" Edit "),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteGisDataField(
                                                            field
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "primary--text text--lighten-1",
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.mdiDelete
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" Delete "),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "caption",
                                    attrs: { colspan: "4" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-center" },
                                      [
                                        _vm._v(
                                          " No UtiliSync Fields added yet. "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showAddUtiliSyncFieldsDialog
                ? _c("AddUtiliSyncFieldsDialog", {
                    attrs: {
                      showAddUtiliSyncFieldsDialog:
                        _vm.showAddUtiliSyncFieldsDialog,
                      mapServiceId: _vm.mapServiceId,
                    },
                    on: {
                      "add-utilisync-fields-dialog-close": function ($event) {
                        _vm.showAddUtiliSyncFieldsDialog = false
                        _vm.$emit("add-utilisync-fields-dialog-close")
                      },
                      "add-utilisync-fields-dialog-added": function ($event) {
                        _vm.showAddUtiliSyncFieldsDialog = false
                        _vm.getGisDataFields()
                      },
                      "field-name-set": _vm.checkFieldName,
                    },
                  })
                : _vm._e(),
              _vm.showEditUtiliSyncFieldDialog
                ? _c("EditUtiliSyncFieldDialog", {
                    attrs: {
                      showEditUtiliSyncFieldDialog:
                        _vm.showEditUtiliSyncFieldDialog,
                      mapServiceId: _vm.mapServiceId,
                      selectedUtiliSyncField: _vm.selectedUtiliSyncField,
                      selectedUtiliSyncFieldIndex:
                        _vm.selectedUtiliSyncFieldIndex,
                    },
                    on: {
                      "edit-utilisync-field-dialog-close": function ($event) {
                        _vm.showEditUtiliSyncFieldDialog = false
                      },
                      "edit-utilisync-fields-dialog-submitted":
                        _vm.onEditUtilisyncFieldSubmitted,
                    },
                  })
                : _vm._e(),
              _c("DuplicateFieldNameDialog", {
                attrs: {
                  showDuplicateFieldNameDialog:
                    _vm.showDuplicateFieldNameDialog,
                },
                on: {
                  "duplicate-field-mame-dialog-close": function ($event) {
                    _vm.showDuplicateFieldNameDialog = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add-utilisync-fields-dialog-close")
                    },
                  },
                },
                [_vm._v(" Return to Utilibot ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }