<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        v-show="showAlert1"
      >
        Do you want to change the default symbology for your Layer?
      </v-alert>
    </v-expand-transition>

    <div class="d-flex justify-end mb-5">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
        v-if="!changeSymbologyChoice"
      >
        <v-list-item
          v-for="(c, i) of changeSymbologyOptions"
          :key="c.label"
          :style="{
            'border-bottom':
              i !== changeSymbologyOptions.length - 1
                ? '1px solid primary'
                : '',
          }"
          color="primary"
          class="my-0 py-0"
          @click="onChoiceClick(c.label)"
        >
          <span>
            {{ c.label }}
          </span>
        </v-list-item>
      </v-list>
      <v-list v-else outlined rounded width="fit-content">
        <v-list-item>
          {{ selectedChangeSymbologyChoiceLabel }}
        </v-list-item>
      </v-list>

      <CustomLayerSymbologyDialog
        @custom-symbology-dialog-close="
          showCustomLayerSymbologyDialog = false;
          symbologySaved = true;
          $emit('layer-saved');
        "
        @symbology-saved="
          showCustomLayerSymbologyDialog = false;
          symbologySaved = true;
          $emit('layer-saved');
        "
        :showCustomLayerSymbologyDialog="showCustomLayerSymbologyDialog"
        v-if="showCustomLayerSymbologyDialog"
        :mapServiceId="mapServiceId"
        :layerObj="layer"
      />
    </div>

    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        v-show="symbologySaved"
      >
        Your symbology has been set! You can always change this later if needed.
      </v-alert>
    </v-expand-transition>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import sleep from "@/mixins/sleep";
import CustomLayerSymbologyDialog from "@/components/layers/create-layer-form/step-5-symbology/CustomLayerSymbologyDialog";

const changeSymbologyOptions = [
  { label: "Change Symbology" },
  { label: "Skip For Now" },
];

export default {
  name: "Step6Symbology",
  props: {
    layerObj: Object,
    mapServiceId: String,
  },
  components: {
    CustomLayerSymbologyDialog,
  },
  computed: {
    selectedChangeSymbologyChoiceLabel() {
      return this.changeSymbologyOptions.find(
        (c) => c.label === this.changeSymbologyChoice
      )?.label;
    },
  },
  data() {
    return {
      changeSymbologyChoice: "",
      changeSymbologyOptions,
      showAlert1: false,
      showCustomLayerSymbologyDialog: false,
      symbologySaved: false,
      layer: {},
    };
  },
  methods: {
    onChoiceClick(label) {
      this.changeSymbologyChoice = label;
      if (label === "Change Symbology") {
        this.showCustomLayerSymbologyDialog = true;
      } else if (label === "Skip For Now") {
        this.symbologySaved = true;
        this.$emit("layer-saved");
      }
    },
  },
  async beforeMount() {
    this.layer = cloneDeep(this.layerObj);
    await sleep(500);
    this.showAlert1 = true;
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 8px !important;
}
</style>
