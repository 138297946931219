var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showUtiliSyncFieldDescriptionDialog,
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("UtiliSync Field Description")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "utilisync-field-descriptions-dialog-close"
                      )
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5 font-weight-bold",
              staticStyle: { "background-color": "#f1f2f1" },
            },
            [
              _c("p", [
                _vm._v(
                  " UtiliSync fields are used to store information about sites on the layer. "
                ),
              ]),
              _c("p", [
                _vm._v(
                  "Common UtiliSync fields include project name, address and status."
                ),
              ]),
              _c("p", [
                _vm._v("You can always add/edit UtiliSync Fields later."),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }