<template>
  <v-card class="m-2">
    <validation-observer ref="createLayerForm">
      <form @submit.prevent="createLayer">
        <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
          <v-toolbar-title>Create Layer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeFormCheck()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          class="px-8"
          :style="{
            'background-color': '#f1f2f1',
            height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
            'overflow-y': 'auto',
            'min-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
            'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
          }"
          ref="layerBox"
        >
          <div ref="layerInnerBox">
            <Step1LayerType
              :layerObj="layer"
              @layer-saved-step-1="layer = $event"
              @layer-saved-step-2="layer = $event"
              @layer-saved-step-3="
                layer = $event;
                step1Complete = true;
              "
              @layer-not-created="$emit('layer-not-created')"
              @browse-arcgis-services-submitted="importLayers($event)"
              @wms-data-requested="onWmsDataRequested"
            />

            <div>
              <v-expand-transition
                v-for="layer in successfullyImportedLayers"
                :key="layer.map_service_id"
              >
                <v-alert
                  color="primary"
                  dark
                  width="85%"
                  class="rounded-tl-lg rounded-tr-lg rounded-br-lg mt-2"
                >
                  Layer "{{
                    layer.type === "Feature Layer" ? layer.name : layer.title
                  }}" was imported successfully.
                </v-alert>
              </v-expand-transition>
            </div>
            <div v-if="massImportComplete === false">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div
              v-for="errorMessage in errorMessages"
              :key="errorMessages[errorMessage]"
            >
              <v-expand-transition>
                <v-alert
                  color="primary"
                  dark
                  width="85%"
                  class="rounded-tl-lg rounded-tr-lg rounded-br-lg mt-2"
                >
                  {{ errorMessage }}
                </v-alert>
              </v-expand-transition>
            </div>
            <Step2NameLayer
              v-show="step1Complete"
              :layerObj="layer"
              :wmsData="wmsData"
              @layer-saved="onStep2Complete"
            />
            <Step3WmsSubLayers
              v-show="
                mapServiceId &&
                step1Complete &&
                step2Complete &&
                ['W'].includes(layer.service_type)
              "
              :layerObj="layer"
              :wmsData="wmsData"
              @layer-saved="onStep3Complete"
            />
            <Step4AddUtiliSyncFields
              v-show="
                mapServiceId &&
                step1Complete &&
                step2Complete &&
                step3Complete &&
                ['U'].includes(layer.service_type)
              "
              :mapServiceId="mapServiceId"
              :layerObj="layer"
              @layer-saved="onStep4Complete"
            />
            <Step5AddReferenceField
              v-if="
                mapServiceId &&
                step1Complete &&
                step2Complete &&
                step3Complete &&
                step4Complete &&
                gisDataFields &&
                gisDataFields.length > 0 &&
                ['U', 'F', 'W'].includes(layer.service_type)
              "
              :mapServiceId="mapServiceId"
              :layerObj="layer"
              @layer-saved="onStep5Complete"
            />

            <Step6Symbology
              v-if="
                mapServiceId &&
                step1Complete &&
                step2Complete &&
                step3Complete &&
                step4Complete &&
                step5Complete &&
                ['U'].includes(layer.service_type)
              "
              :layerObj="layer"
              :mapServiceId="mapServiceId"
              @layer-saved="onStep6Complete"
            />

            <v-expand-transition>
              <v-alert
                color="primary"
                dark
                width="85%"
                class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
                v-show="showCloseButton"
              >
                Thanks for using the Create Layer UtiliBot to create your layer!
              </v-alert>
            </v-expand-transition>

            <div class="d-flex justify-end">
              <v-btn
                v-if="showCloseButton"
                @click="
                  $emit('create-layer-form-submitted');
                  showCloseButton = false;
                "
              >
                Close
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </form>
    </validation-observer>

    <ExitLayerCreationDialog
      :showExitLayerCreationDialog="showExitLayerCreationDialog"
      :mapServiceId="mapServiceId"
      @exit-layer-creation-dialog-close="showExitLayerCreationDialog = false"
      @create-layer-form-close="
        showExitLayerCreationDialog = false;
        $emit('create-layer-form-close');
      "
    />
  </v-card>
</template>

<script>
import Step1LayerType from "@/components/layers/create-layer-form/Step1LayerType";
import Step2NameLayer from "@/components/layers/create-layer-form/Step2NameLayer";
import Step3WmsSubLayers from "@/components/layers/create-layer-form/Step3WmsSubLayers";
import Step4AddUtiliSyncFields from "@/components/layers/create-layer-form/Step4AddUtiliSyncFields";
import Step5AddReferenceField from "@/components/layers/create-layer-form/Step5AddReferenceField";
import Step6Symbology from "@/components/layers/create-layer-form/Step6Symbology";
import ExitLayerCreationDialog from "@/components/layers/create-layer-form/ExitLayerCreationDialog";
import axios from "axios";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import generateSingleSymbol from "@/mixins/generateSingleSymbol";
import sleep from "@/mixins/sleep";

const APIURL = process.env.VUE_APP_API_URL;
const layer = {
  locate_request_provider_account_id: undefined,
  map_service_id: "",
  ref_field: "",
  renderer: {
    apply_renderer_to_feature_service: "",
    convert_date_to_days: "",
    overrides: [],
    reference_field: "",
    renderer_id: "",
    renderer_symbols: [
      {
        field_option_label: "",
        field_option_value: "",
        fill_color: "",
        gis_data_field_option_id: "",
        label: "",
        max_value: "",
        min_value: "",
        outline_color: "",
        picture_size: "",
        renderer_id: "",
        renderer_symbol_id: "",
        style: "",
        symbol_type: "",
        url: "",
        width: "",
      },
    ],
    renderer_type: "",
    type: "",
  },
  service_name: "",
  service_type: "",
  service_url: "",
  stormwater_enabled: false,
  token_type: "NONE",
  user_group_id: "",
};

export default {
  name: "CreateLayerForm",
  components: {
    Step1LayerType,
    Step2NameLayer,
    Step3WmsSubLayers,
    Step4AddUtiliSyncFields,
    Step5AddReferenceField,
    Step6Symbology,
    ExitLayerCreationDialog,
  },
  mixins: [contentHeightMixin],
  data() {
    return {
      layer,
      mapServiceId: "",
      step1Complete: false,
      step2Complete: false,
      step3Complete: false,
      step4Complete: false,
      step5Complete: false,
      step6Complete: false,
      showCloseButton: false,
      showExitLayerCreationDialog: false,
      gisDataFields: [],
      contentHeight: 0,
      successfullyImportedLayers: [],
      errorMessages: [],
      massImportComplete: true,
      layerCreationComplete: false,
      wmsData: undefined,
    };
  },
  methods: {
    onWmsDataRequested(wmsData) {
      this.wmsData = wmsData;
    },
    closeFormCheck() {
      if (this.layerCreationComplete && this.massImportComplete) {
        this.$emit("create-layer-form-close");
      } else {
        this.showExitLayerCreationDialog = true;
      }
    },
    async importLayers(layersForImport) {
      this.massImportComplete = false;
      for (const layer of layersForImport) {
        try {
          await this.getImportLayerData(layer);
          await this.createLayer();
          this.successfullyImportedLayers.push(layer);
        } catch (error) {
          this.massImportComplete = true;
          this.errorMessages.push(error);
          await sleep(1000);
          this.showCloseButton = true;
        }
      }
      this.massImportComplete = true;
      await sleep(1000);
      this.showCloseButton = true;
    },
    async getImportLayerData(layer) {
      this.layer.service_url = layer.url;

      this.layer.service_type = this.layer.service_url.includes("FeatureServer")
        ? "F"
        : "S";

      this.layer.token_type = layer.access === "public" ? "NONE" : "AGOL";

      let requestURL = `${layer.url}?f=pjson`;
      if (this.layer.token_type !== "NONE" || this.layer.service_type === "F") {
        requestURL += `&token=${localStorage.getItem("esri_token")}`;
      }

      try {
        const { data } = await axios.get(`${requestURL}`);

        if (data.error && data.error.code === 400) {
          throw new Error(data.error.details[0]);
        }

        this.layer.service_name =
          this.layer.service_type === "F"
            ? data.name
            : data?.documentInfo?.Title;
      } catch (error) {
        if (error.toString().includes("The requested layer")) {
          throw new Error(
            "Unable to import layer. Could not connect to the service. " + error
          );
        } else {
          throw new Error(
            "Unable to import layer. Could not connect to the service."
          );
        }
      }
    },
    checkFeatureLayer() {
      if (["L", "S"].includes(this.layer.service_type)) {
        this.step4Complete = true;
        this.step5Complete = true;
        this.onStep5Complete();
      }
    },
    async createLayer() {
      const { layer } = this;
      const {
        token_type: tokenType,
        service_url: serviceUrl,
        service_type: serviceType,
      } = layer;
      if (!["U", "L"].includes(serviceType) && (!tokenType || !serviceUrl)) {
        return;
      }
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      layer.user_group_id = userGroupId;
      const {
        data: {
          results: {
            map_service: { map_service_id: mapServiceId },
          },
        },
      } = await axios.post(`${APIURL}/map_services`, layer);
      this.mapServiceId = mapServiceId;

      this.layerCreationComplete = true;
    },
    async updateLayer() {
      const { layer, mapServiceId } = this;
      const {
        token_type: tokenType,
        service_url: serviceUrl,
        service_type: serviceType,
      } = layer;
      if (!mapServiceId) {
        return;
      }

      if (!["U", "F"].includes(serviceType) && (!tokenType || !serviceUrl)) {
        return;
      }
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      layer.user_group_id = userGroupId;
      await axios.put(`${APIURL}/map_services/${mapServiceId}`, layer);
    },
    async scrollToBottom() {
      await this.$nextTick();
      if (this.$refs.layerBox) {
        this.$refs.layerBox.scrollTo(0, this.$refs.layerBox.scrollHeight);
      }
    },
    async getFeatureServiceFields() {
      const { service_url: featureServiceUrl } = this.layer;
      if (!featureServiceUrl) {
        return [];
      }
      const token = localStorage.getItem("esri_token");
      const params = {
        f: "pjson",
        token,
      };
      const {
        data: { fields = [] },
      } = await axios.get(featureServiceUrl, { params });
      return fields;
    },
    async getGisDataFields() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: mapServiceId,
        },
      });
      return results;
    },
    async onStep2Complete(layer) {
      this.layer = layer;
      this.step2Complete = true;
      await this.createLayer();
      let hasSublayers = false;
      try {
        if (["W"].includes(layer.service_type)) {
          const { wmsData } = this;
          if (
            Array.isArray(wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer)
          ) {
            const sublayers =
              wmsData?.WMS_Capabilities?.Capability?.Layer?.Layer;
            hasSublayers = Array.isArray(sublayers) && sublayers.length > 0;
          }
        }
      } catch (error) {
        console.warn(error);
      } finally {
        if (["L", "S", "T"].includes(this.layer.service_type)) {
          this.step3Complete = true;
          this.step4Complete = true;
          this.step5Complete = true;
          this.step6Complete = true;
          this.showCloseButton = true;
        } else if (["W"].includes(layer.service_type) && !hasSublayers) {
          this.step3Complete = true;
          this.step4Complete = true;
          this.step5Complete = true;
          this.step6Complete = true;
          this.showCloseButton = true;
        } else if (["F"].includes(layer.service_type)) {
          this.step3Complete = true;
          this.step4Complete = true;
          this.step5Complete = true;
          this.step6Complete = true;
          this.showCloseButton = true;
        } else if (["U"].includes(layer.service_type)) {
          this.step3Complete = true;
        }
      }
    },
    onStep3Complete({ sub_layers: subLayers }) {
      this.layer = { ...this.layer, sub_layers: subLayers };
      this.updateLayer();
      this.step3Complete = true;
      if (["W"].includes(this.layer.service_type)) {
        this.step4Complete = true;
        this.step5Complete = true;
        this.step6Complete = true;
        this.showCloseButton = true;
      }
    },
    async onStep4Complete(layer) {
      this.layer = layer;
      this.updateLayer();
      this.step4Complete = true;
      if (this.layer.service_type === "F") {
        this.gisDataFields = await this.getFeatureServiceFields();
        if (this.gisDataFields?.length === 0) {
          this.step5Complete = true;
        }
      } else if (this.layer.service_type === "U") {
        this.gisDataFields = await this.getGisDataFields();
        if (this.gisDataFields?.length === 0) {
          this.step5Complete = true;
        }
      } else {
        this.step5Complete = true;
      }
    },
    async onStep5Complete(layer) {
      this.layer = layer;
      await this.updateLayer();
      await this.addRendererIfNotExists();
      this.step5Complete = true;
      if (!["U"].includes(this.layer.service_type)) {
        this.step4Complete = true;
        this.showCloseButton = true;
      }
    },
    async onStep6Complete() {
      await this.updateLayer();
      await this.addRendererIfNotExists();
      this.showCloseButton = true;
    },
    async addRendererIfNotExists() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/renderer/map_service/${mapServiceId}`);
      if (results) {
        return;
      }
      const renderer = {
        renderer_id: null,
        map_service_id: mapServiceId,
        convert_date_to_days: false,
        type: "Point: Picture",
        reference_field: null,
        renderer_type: "unique_value",
        apply_renderer_to_feature_service: false,
      };
      const {
        data: {
          results: {
            renderer: { renderer_id: rendererId },
          },
        },
      } = await axios.post(`${APIURL}/renderers`, renderer);
      axios.post(`${APIURL}/renderer_symbol`, {
        ...generateSingleSymbol(),
        label: this.layer.service_name,
        symbol_type: "image",
        renderer_id: rendererId,
      });
    },
    onResize() {
      this.contentHeight =
        window.innerHeight - (this.$refs.toolbar?.$el?.clientHeight ?? 0);
    },
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        this.scrollToBottom();
      });
    });
    resizeObserver.observe(this.$refs.layerInnerBox);

    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 8px !important;
}
</style>
