var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
      model: {
        value: _vm.showAddUserDialog,
        callback: function ($$v) {
          _vm.showAddUserDialog = $$v
        },
        expression: "showAddUserDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { ref: "toolbar", attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Add User")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add-user-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              ref: "userBox",
              staticClass: "pl-8 pr-8",
              style: {
                "background-color": "#f1f2f1",
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "auto",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              _c(
                "div",
                { ref: "userInnerBox", staticClass: "py-5" },
                [
                  _c("Step1UserForm", {
                    on: { "step-1-finished": _vm.onStep1Finished },
                  }),
                  _vm.step1Finished
                    ? _c("Step2UserForm", {
                        attrs: { userGroup: _vm.userGroup },
                        on: { "step-2-finished": _vm.onStep2Finished },
                      })
                    : _vm._e(),
                  _vm.step1Finished && _vm.step2Finished
                    ? _c("Step3UserFormEmail", {
                        attrs: { savedUser: _vm.user },
                        on: { "step-3-finished": _vm.onStep3Finished },
                      })
                    : _vm._e(),
                  _vm.step1Finished &&
                  _vm.step2Finished &&
                  _vm.step3Finished &&
                  _vm.showStep4
                    ? _c("Step4UserFormCellPhone", {
                        attrs: { savedUser: _vm.user },
                        on: { "step-4-finished": _vm.onStep4Finished },
                      })
                    : _vm._e(),
                  _vm.step1Finished &&
                  _vm.step2Finished &&
                  _vm.step3Finished &&
                  _vm.step4Finished
                    ? _c("Step5UserFormTimeZone", {
                        on: { "step-5-finished": _vm.onStep5Finished },
                      })
                    : _vm._e(),
                  _vm.step1Finished &&
                  _vm.step2Finished &&
                  _vm.step3Finished &&
                  _vm.step4Finished &&
                  _vm.step5Finished
                    ? _c("Step6UserFormAdmin", {
                        attrs: { savedUser: _vm.user },
                        on: { "step-6-finished": _vm.onStep6Finished },
                      })
                    : _vm._e(),
                  _vm.showStep7
                    ? _c("Step7UserFormLocator", {
                        attrs: { savedUser: _vm.user },
                        on: { "step-7-finished": _vm.onStep7Finished },
                      })
                    : _vm._e(),
                  _vm.showStep8
                    ? _c("Step8UserFormUserType", {
                        attrs: {
                          savedUser: _vm.user,
                          userGroup: _vm.userGroup,
                        },
                        on: { "step-8-finished": _vm.onStep8Finished },
                      })
                    : _vm._e(),
                  _vm.step1Finished &&
                  _vm.step2Finished &&
                  _vm.step3Finished &&
                  _vm.step4Finished &&
                  _vm.step5Finished &&
                  _vm.step6Finished &&
                  ((_vm.showStep7 && _vm.step7Finished) || !_vm.showStep7) &&
                  ((_vm.showStep8 && _vm.step8Finished) || !_vm.showStep8)
                    ? _c("Step9UserFormMap", {
                        attrs: { savedUser: _vm.user },
                        on: { "step-9-finished": _vm.onStep9Finished },
                      })
                    : _vm._e(),
                  _vm.step9Finished && _vm.computedAppChoices.length > 1
                    ? _c("Step10UserFormApp", {
                        attrs: {
                          savedUser: _vm.user,
                          appForPasswordCreationChoices: _vm.computedAppChoices,
                        },
                        on: {
                          "step-10-finished": function ($event) {
                            return _vm.onStep10Finished($event)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.step10Finished && _vm.departments.length > 0
                    ? _c("Step11UserDepartment", {
                        attrs: {
                          savedUser: _vm.user,
                          departmentChoices: _vm.departmentChoices,
                        },
                        on: {
                          "step-11-finished": function ($event) {
                            return _vm.onStep11Finished($event)
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "v-expand-transition",
                    [
                      _c(
                        "v-alert",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.step1Finished &&
                                _vm.step2Finished &&
                                _vm.step3Finished &&
                                _vm.step4Finished &&
                                _vm.step5Finished &&
                                _vm.step6Finished &&
                                ((_vm.showStep7 && _vm.step7Finished) ||
                                  !_vm.showStep7) &&
                                ((_vm.showStep8 && _vm.step8Finished) ||
                                  !_vm.showStep8) &&
                                _vm.step9Finished &&
                                _vm.step10Finished &&
                                (_vm.step11Finished ||
                                  _vm.departments.length === 0),
                              expression:
                                "\n              step1Finished &&\n              step2Finished &&\n              step3Finished &&\n              step4Finished &&\n              step5Finished &&\n              step6Finished &&\n              ((showStep7 && step7Finished) || !showStep7) &&\n              ((showStep8 && step8Finished) || !showStep8) &&\n              step9Finished &&\n              step10Finished &&\n              (step11Finished || departments.length === 0)\n            ",
                            },
                          ],
                          staticClass:
                            "rounded-tl-lg rounded-tr-lg rounded-br-lg mb-5",
                          attrs: { color: "primary", dark: "", width: "85%" },
                        },
                        [
                          _vm._v(
                            " Perfect. I have everything I need to create this user. Just give me a minute to do that. "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expand-transition",
                    [
                      _c(
                        "v-alert",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.step1Finished &&
                                _vm.step2Finished &&
                                _vm.step3Finished &&
                                _vm.step4Finished &&
                                _vm.step5Finished &&
                                _vm.step6Finished &&
                                ((_vm.showStep7 && _vm.step7Finished) ||
                                  !_vm.showStep7) &&
                                ((_vm.showStep8 && _vm.step8Finished) ||
                                  !_vm.showStep8) &&
                                _vm.step9Finished &&
                                _vm.step10Finished &&
                                _vm.step11Finished,
                              expression:
                                "\n              step1Finished &&\n              step2Finished &&\n              step3Finished &&\n              step4Finished &&\n              step5Finished &&\n              step6Finished &&\n              ((showStep7 && step7Finished) || !showStep7) &&\n              ((showStep8 && step8Finished) || !showStep8) &&\n              step9Finished &&\n              step10Finished &&\n              step11Finished\n            ",
                            },
                          ],
                          staticClass:
                            "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                          attrs: { color: "primary", dark: "", width: "85%" },
                        },
                        [
                          _vm.userCreated
                            ? [
                                _vm._v(
                                  " All set! The new user has been created. You can see " +
                                    _vm._s(_vm.user.firstName) +
                                    "'s user details and set " +
                                    _vm._s(_vm.user.firstName) +
                                    "'s notifications by click on that row in the users table. Thanks for coming by today. It's been a pleasure working with you! "
                                ),
                              ]
                            : [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "white" },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }