var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0 mb-5",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _c("div", [
                _vm._v(" Upon reviewing your account, I see you have: "),
                _c("ul", [
                  _vm.numViewOnlyUsersAvailable > 0
                    ? _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.numViewOnlyUsersAvailable) +
                            " View-Only Users available "
                        ),
                      ])
                    : _vm._e(),
                  (_vm.userGroup.field_reports_plan_type === "organization" ||
                    _vm.userGroup.field_reports_plan_type === "sites") &&
                  _vm.numStandardUsersAvailable > 0
                    ? _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.numStandardUsersAvailable) +
                            " Standard Users available "
                        ),
                      ])
                    : _vm._e(),
                  _vm.userGroup.field_reports_plan_type === "power_users" &&
                  _vm.numPowerUsersAvailable > 0
                    ? _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.numPowerUsersAvailable) +
                            " Power Users available "
                        ),
                      ])
                    : _vm._e(),
                  _vm.numViewOnlyUsersAvailable <= 0 &&
                  _vm.numPowerUsersAvailable <= 0 &&
                  _vm.numStandardUsersAvailable <= 0
                    ? _c("li", [_vm._v(" No users available. ")])
                    : _vm._e(),
                  !_vm.userGroup.field_reports_plan_type
                    ? _c("li", [
                        _vm._v(
                          " No licensing set for organization. Reach out to "
                        ),
                        _c("strong", [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "white" },
                              attrs: { href: "mailto:support@utilisync.com" },
                            },
                            [_vm._v("UtiliSync Support")]
                          ),
                        ]),
                        _vm._v(" to get this set. "),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-0",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm.numViewOnlyUsersAvailable <= 0 &&
              _vm.numPowerUsersAvailable <= 0 &&
              _vm.numStandardUsersAvailable <= 0
                ? _c("div", [
                    _vm._v(" Reach out to "),
                    _c("strong", [
                      _c(
                        "a",
                        {
                          staticStyle: { color: "white" },
                          attrs: { href: "mailto:support@utilisync.com" },
                        },
                        [_vm._v("UtiliSync Support")]
                      ),
                    ]),
                    _vm._v(" to discuss options for creating this user. "),
                  ])
                : _c("div", [
                    _vm._v(" What type of user do you want to assign to "),
                    _c("strong", [_vm._v(_vm._s(_vm.savedUser.firstName))]),
                    _vm._v(" for the Inspect app? "),
                  ]),
              _c("div", { staticClass: "mt-4" }, [
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      " View-Only Users - Cannot complete forms but can access the app to view its information "
                    ),
                  ]),
                  _vm.userGroup.field_reports_plan_type === "power_users"
                    ? _c("li", [
                        _vm._v(
                          " Power Users - Can create an unlimited number of field reports. "
                        ),
                      ])
                    : _vm._e(),
                  _vm.userGroup.field_reports_plan_type === "organization" ||
                  _vm.userGroup.field_reports_plan_type === "sites"
                    ? _c("li", [
                        _vm._v(
                          " Standard Users - Can complete forms, but are limited based on the number of form submissions your account is licensed for. "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      !_vm.userTypeChoiceSubmitted
        ? _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-list",
                {
                  staticClass: "py-0",
                  style: {
                    width: "fit-content",
                    "border-radius": "1%",
                    border: "1px solid primary",
                  },
                  attrs: { dense: "" },
                },
                _vm._l(_vm.filteredUserTypeChoices, function (c, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: c.label,
                      staticClass: "my-0 py-0",
                      style: {
                        "border-bottom":
                          i !== _vm.filteredUserTypeChoices.length - 1
                            ? "1px solid primary"
                            : "",
                        "background-color": "white",
                      },
                      on: {
                        click: function ($event) {
                          _vm.userTypeChoice = c.value
                          _vm.userTypeChoiceSubmitted = true
                          _vm.saveUserTypeChoice()
                        },
                      },
                    },
                    [
                      _c("span", { style: { color: "primary" } }, [
                        _vm._v(" " + _vm._s(c.label) + " "),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex justify-end my-5" },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    outlined: "",
                    rounded: "",
                    width: "fit-content",
                    dense: "",
                  },
                },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.saveUserTypeChoiceLabel) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }