<template>
  <div class="mt-2">
    <v-expand-transition>
      <div v-show="showAlert1">
        <v-alert
          color="primary"
          dark
          width="85%"
          class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        >
          Do you want to set one of the created UtiliSync Fields to be a
          Reference Field?
        </v-alert>
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <div class="d-flex justify-end" v-show="showAlert1">
        <v-btn
          text
          color="primary"
          @click="showReferenceFieldDescriptionDialog = true"
        >
          View Reference Field Description
        </v-btn>
      </div>
    </v-expand-transition>

    <ReferenceFieldDescriptionDialog
      :showReferenceFieldDescriptionDialog="showReferenceFieldDescriptionDialog"
      @reference-type-descriptions-dialog-close="
        showReferenceFieldDescriptionDialog = false
      "
    />

    <div class="d-flex justify-end mb-5">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
        v-if="!refFieldOptionsChoice"
      >
        <v-list-item
          v-for="(r, i) of refFieldOptions"
          :key="r.label"
          :style="{
            'border-bottom':
              i !== refFieldOptions.length - 1 ? '1px solid primary' : '',
            'background-color':
              refFieldOptionsChoice === r.label ? 'primary' : 'white',
          }"
          color="primary"
          class="my-0 py-0"
          @click="refFieldOptionsChoice = r.label"
        >
          <span
            :style="{
              color: refFieldOptionsChoice === r.label ? 'white' : 'primary',
            }"
          >
            {{ r.label }}
          </span>
        </v-list-item>
      </v-list>
      <v-list v-else outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ refFieldOptionsChoice }}
        </v-list-item>
      </v-list>
    </div>

    <v-expand-transition>
      <v-alert
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
        v-show="refFieldOptionsChoice === 'Add Reference Field'"
      >
        Which UtiliSync Field do you want your Reference Field to be?
      </v-alert>
    </v-expand-transition>

    <div
      class="d-flex justify-end mb-5"
      v-if="refFieldOptionsChoice === 'Add Reference Field'"
    >
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
        v-if="!selectedGisDataFieldOptionsLabel"
      >
        <v-list-item
          v-for="(g, i) of gisDataFieldOptions"
          :key="g.value"
          :style="{
            'border-bottom':
              i !== gisDataFieldOptions.length - 1 ? '1px solid primary' : '',
            'background-color':
              layer.ref_field === g.value ? 'primary' : 'white',
          }"
          color="primary"
          class="my-0 py-0"
          @click="
            layer.ref_field = g.value;
            save();
          "
        >
          <span
            :style="{
              color: layer.ref_field === g.value ? 'white' : 'primary',
            }"
          >
            {{ g.label }}
          </span>
        </v-list-item>
      </v-list>
      <v-list v-else outlined rounded width="fit-content">
        <v-list-item>
          {{ selectedGisDataFieldOptionsLabel }}
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";
import sleep from "@/mixins/sleep";
import ReferenceFieldDescriptionDialog from "@/components/layers/create-layer-form/step-4-add-reference-field/ReferenceFieldDescriptionDialog";

const APIURL = process.env.VUE_APP_API_URL;
const refFieldOptions = [
  { label: "Add Reference Field" },
  { label: "Skip For Now" },
];

export default {
  name: "Step5AddReferenceField",
  components: {
    ReferenceFieldDescriptionDialog,
  },
  props: {
    layerObj: Object,
    mapServiceId: String,
  },
  computed: {
    selectedGisDataFieldOptionsLabel() {
      return this.gisDataFieldOptions.find(
        (g) => g.value === this.layer.ref_field
      )?.label;
    },
    gisDataFieldOptions() {
      if (this.layer.service_type === "F") {
        return this.featureServiceGisFields.map((f) => {
          const { name, alias } = f;
          return {
            label: alias || name,
            value: name,
          };
        });
      }
      return this.gisDataFields.map((g) => {
        const { gis_data_field_id: gisDataFieldId, name } = g;
        return {
          label: name,
          value: gisDataFieldId,
        };
      });
    },
  },
  data() {
    return {
      showReferenceFieldDescriptionDialog: false,
      refFieldOptions,
      refFieldOptionsChoice: "",
      gisDataFields: [],
      layer: {},
      showAlert1: false,
      featureServiceGisFields: [],
    };
  },
  methods: {
    async getFeatureServiceFields() {
      const { service_url: featureServiceUrl } = this.layer;
      if (!featureServiceUrl) {
        return;
      }
      const token = localStorage.getItem("esri_token");
      const params = {
        f: "pjson",
        token,
      };
      const {
        data: { fields = [] },
      } = await axios.get(featureServiceUrl, { params });
      this.featureServiceGisFields = fields;
    },
    async getGisDataFields() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: mapServiceId,
        },
      });
      this.gisDataFields = results;
      this.$emit("get-gis-data-fields", this.gisDataFields.length);
    },
    save() {
      this.$emit("layer-saved", this.layer);
    },
  },
  async beforeMount() {
    this.layer = cloneDeep(this.layerObj);
    await this.getGisDataFields();
    await this.getFeatureServiceFields();
    await sleep(500);
    this.showAlert1 = true;
  },
  watch: {
    layerObj: {
      deep: true,
      handler(val) {
        this.layer = {
          ...this.layer,
          ...val,
        };
      },
    },
    refFieldOptionsChoice(val) {
      if (val === "Add Reference Field") {
        this.getGisDataFields();
      } else if (val === "Skip For Now") {
        this.save();
      }
    },
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 8px !important;
}
</style>
