var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAlert1,
                expression: "showAlert1",
              },
            ],
          },
          [
            _c(
              "v-alert",
              {
                staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                attrs: { color: "primary", dark: "", width: "85%" },
              },
              [
                _vm._v(
                  " Do you want to set one of the created UtiliSync Fields to be a Reference Field? "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAlert1,
                expression: "showAlert1",
              },
            ],
            staticClass: "d-flex justify-end",
          },
          [
            _c(
              "v-btn",
              {
                attrs: { text: "", color: "primary" },
                on: {
                  click: function ($event) {
                    _vm.showReferenceFieldDescriptionDialog = true
                  },
                },
              },
              [_vm._v(" View Reference Field Description ")]
            ),
          ],
          1
        ),
      ]),
      _c("ReferenceFieldDescriptionDialog", {
        attrs: {
          showReferenceFieldDescriptionDialog:
            _vm.showReferenceFieldDescriptionDialog,
        },
        on: {
          "reference-type-descriptions-dialog-close": function ($event) {
            _vm.showReferenceFieldDescriptionDialog = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-end mb-5" },
        [
          !_vm.refFieldOptionsChoice
            ? _c(
                "v-list",
                {
                  staticClass: "py-0",
                  style: {
                    width: "fit-content",
                    "border-radius": "1%",
                    border: "1px solid primary",
                  },
                  attrs: { dense: "" },
                },
                _vm._l(_vm.refFieldOptions, function (r, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: r.label,
                      staticClass: "my-0 py-0",
                      style: {
                        "border-bottom":
                          i !== _vm.refFieldOptions.length - 1
                            ? "1px solid primary"
                            : "",
                        "background-color":
                          _vm.refFieldOptionsChoice === r.label
                            ? "primary"
                            : "white",
                      },
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.refFieldOptionsChoice = r.label
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          style: {
                            color:
                              _vm.refFieldOptionsChoice === r.label
                                ? "white"
                                : "primary",
                          },
                        },
                        [_vm._v(" " + _vm._s(r.label) + " ")]
                      ),
                    ]
                  )
                }),
                1
              )
            : _c(
                "v-list",
                {
                  attrs: {
                    outlined: "",
                    rounded: "",
                    width: "fit-content",
                    dense: "",
                  },
                },
                [
                  _c("v-list-item", [
                    _vm._v(" " + _vm._s(_vm.refFieldOptionsChoice) + " "),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.refFieldOptionsChoice === "Add Reference Field",
                  expression: "refFieldOptionsChoice === 'Add Reference Field'",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [
              _vm._v(
                " Which UtiliSync Field do you want your Reference Field to be? "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.refFieldOptionsChoice === "Add Reference Field"
        ? _c(
            "div",
            { staticClass: "d-flex justify-end mb-5" },
            [
              !_vm.selectedGisDataFieldOptionsLabel
                ? _c(
                    "v-list",
                    {
                      staticClass: "py-0",
                      style: {
                        width: "fit-content",
                        "border-radius": "1%",
                        border: "1px solid primary",
                      },
                      attrs: { dense: "" },
                    },
                    _vm._l(_vm.gisDataFieldOptions, function (g, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: g.value,
                          staticClass: "my-0 py-0",
                          style: {
                            "border-bottom":
                              i !== _vm.gisDataFieldOptions.length - 1
                                ? "1px solid primary"
                                : "",
                            "background-color":
                              _vm.layer.ref_field === g.value
                                ? "primary"
                                : "white",
                          },
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.layer.ref_field = g.value
                              _vm.save()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.layer.ref_field === g.value
                                    ? "white"
                                    : "primary",
                              },
                            },
                            [_vm._v(" " + _vm._s(g.label) + " ")]
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _c(
                    "v-list",
                    {
                      attrs: {
                        outlined: "",
                        rounded: "",
                        width: "fit-content",
                      },
                    },
                    [
                      _c("v-list-item", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.selectedGisDataFieldOptionsLabel) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }