<template>
  <div>
    <v-btn fab color="primary" class="elevation-2 mb-3" small>
      <img src="@/assets/UtiliBot_Face.svg" />
    </v-btn>
    <v-expand-transition>
      <v-alert
        v-show="showStep1"
        color="primary"
        dark
        width="85%"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
      >
        Hi! I'm the Create Layer UtiliBot and I will guide you through the
        process of creating a new layer. Let's begin.
      </v-alert>
    </v-expand-transition>
    <v-expand-transition>
      <div v-show="showStep2">
        <v-alert
          color="primary"
          dark
          class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
          width="fit-content"
          v-if="!viewLayerTypeDescriptions"
        >
          What is the layer type?
        </v-alert>
      </div>
    </v-expand-transition>

    <v-expand-transition v-if="showStep3">
      <div>
        <div class="d-flex justify-end">
          <v-btn
            v-if="!selectedServiceTypeLabel"
            text
            color="primary"
            @click="showLayerTypeDescriptionsDialog = true"
          >
            View Layer Type Descriptions
          </v-btn>
        </div>
        <div class="d-flex justify-end mb-2">
          <v-list
            :style="{
              width: 'fit-content',
              'border-radius': '1%',
              border: '1px solid primary',
            }"
            class="py-0"
            dense
            v-if="!selectedServiceTypeLabel"
          >
            <v-list-item
              v-for="(s, i) of serviceTypeOptions"
              :key="s.value"
              :style="{
                'border-bottom':
                  i !== serviceTypeOptions.length - 1
                    ? '1px solid primary'
                    : '',
                'background-color':
                  layer.service_type === s.value ? 'primary' : 'white',
              }"
              color="primary"
              class="my-0 py-0"
              @click="onStep1Click(s.value)"
            >
              <span
                :style="{
                  color: layer.service_type === s.value ? 'white' : 'primary',
                }"
              >
                {{ s.label }}
              </span>
            </v-list-item>
          </v-list>
          <v-list v-else outlined rounded width="fit-content">
            <v-list-item>
              {{ selectedServiceTypeLabel }}
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-expand-transition>
    <LayerTypeDescriptionsDialog
      :showLayerTypeDescriptionsDialog="showLayerTypeDescriptionsDialog"
      @layer-type-descriptions-dialog-close="
        showLayerTypeDescriptionsDialog = false
      "
    />

    <template v-if="['FS', 'F', 'S'].includes(layer.service_type)">
      <v-alert
        color="primary"
        dark
        width="fit-content"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
      >
        <template v-if="!hasEsriToken">Enter the service URL.</template>
        <template v-else>Enter the service URL or</template>
        <v-btn
          v-if="hasEsriToken"
          class="ml-2"
          color="secondary"
          @click="showBrowseArcGIS = true"
          :disabled="layer.service_url !== ''"
        >
          Browse ArcGIS Services
        </v-btn>

        <BrowseArcGISServices
          v-if="showBrowseArcGIS"
          @browse-arcgis-services-close="showBrowseArcGIS = false"
          @browse-arcgis-services-submitted="
            showBrowseArcGIS = false;
            importingServices = true;
            serviceUrlSubmitted = true;
            $emit('browse-arcgis-services-submitted', $event);
          "
          :showBrowseArcGIS="showBrowseArcGIS"
        />
      </v-alert>

      <div class="d-flex justify-end" v-if="!importingServices">
        <v-btn
          text
          color="primary"
          @click="showSupportedUrlExampleDialog = true"
        >
          Supported URL Examples
        </v-btn>
      </div>

      <SupportedUrlExampleDialog
        @supported-url-dialog-close="showSupportedUrlExampleDialog = false"
        :showSupportedUrlExampleDialog="showSupportedUrlExampleDialog"
        :serviceType="layer.service_type"
      />

      <div v-if="['FS', 'F', 'S'].includes(layer.service_type)">
        <div v-if="!importingServices && !manualInput">
          <validation-observer ref="serviceUrlForm">
            <form @submit.prevent>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Service URL"
                :rules="{
                  required: true,
                  regex:
                    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                  ends_with_regex: true,
                }"
              >
                <v-textarea
                  outlined
                  color="#E5E5E5"
                  v-model="layer.service_url"
                  hide-details="auto"
                  name="serviceUrl"
                  background-color="white"
                  :error-messages="errors"
                  :success="valid"
                  full-width
                  auto-grow
                  rows="1"
                  @keypress="onKeyPress"
                >
                  <template v-slot:append>
                    <div class="mt-n2">
                      <v-btn
                        icon
                        type="submit"
                        color="primary"
                        :disabled="!valid"
                        @click="testConnection()"
                      >
                        <v-icon>
                          {{ mdiSend }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-textarea>
              </validation-provider>
            </form>
          </validation-observer>
        </div>
        <div
          v-else-if="importingServices && !manualInput"
          class="d-flex justify-end"
        >
          <v-list outlined rounded width="fit-content" dense>
            <v-list-item style="word-break: break-all">
              Import Layers
            </v-list-item>
          </v-list>
        </div>
        <div v-else class="d-flex justify-end">
          <v-list outlined rounded width="85%" dense>
            <v-list-item style="word-break: break-all">
              {{ layer.service_url }}
            </v-list-item>
          </v-list>
        </div>
      </div>
    </template>
    <template v-else-if="['W'].includes(layer.service_type)">
      <v-alert
        color="primary"
        dark
        width="fit-content"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
      >
        Enter the service URL.
      </v-alert>

      <validation-observer ref="serviceUrlForm" v-if="!serviceUrlSubmitted">
        <form @submit.prevent class="my-2">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Service URL"
            :rules="{
              required: true,
              regex:
                /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
            }"
          >
            <v-textarea
              outlined
              color="#E5E5E5"
              v-model.trim="layer.service_url"
              hide-details="auto"
              name="serviceUrl"
              background-color="white"
              :error-messages="errors"
              :success="valid"
              full-width
              auto-grow
              rows="1"
              @keypress="onKeyPress"
            >
              <template v-slot:append>
                <div class="mt-n2">
                  <v-btn
                    icon
                    type="submit"
                    color="primary"
                    :disabled="!valid"
                    @click="testWmsLayerUrlConnection()"
                  >
                    <v-icon>
                      {{ mdiSend }}
                    </v-icon>
                  </v-btn>
                </div>
              </template>

              <template v-slot:append-outer>
                <v-progress-circular
                  :size="20"
                  indeterminate
                  v-if="testingLayer"
                ></v-progress-circular>
              </template>
            </v-textarea>
          </validation-provider>
        </form>
      </validation-observer>
      <div v-else class="d-flex justify-end">
        <v-list outlined rounded width="85%" dense>
          <v-list-item style="word-break: break-all">
            {{ layer.service_url }}
          </v-list-item>
        </v-list>
      </div>
    </template>

    <div v-if="layer.service_type === 'L'">
      <v-expand-transition>
        <div v-show="layer.service_type === 'L'">
          <v-alert
            color="primary"
            dark
            width="85%"
            class="rounded-tl-lg rounded-tr-lg rounded-br-lg d-flex justify-space-between"
            v-show="accounts811.length > 1"
          >
            It looks like you have more than one 811 Code in your organization.
            What 811 Code should be displayed on this layer?
          </v-alert>
        </div>
      </v-expand-transition>

      <div
        class="d-flex justify-end mb-5"
        v-if="accounts811.length > 1 && layer.service_type === 'L'"
      >
        <v-list
          :style="{
            width: 'fit-content',
            'border-radius': '1%',
            border: '1px solid primary',
          }"
          class="py-0"
          dense
          v-if="selected811AccountLabel === undefined"
        >
          <v-list-item
            v-for="(a, i) of account811Choices"
            :key="a.value"
            :style="{
              'border-bottom':
                i !== account811Choices.length - 1 ? '1px solid primary' : '',
              'background-color':
                layer.locate_request_provider_account_id === a.value
                  ? 'primary'
                  : 'white',
            }"
            color="primary"
            class="my-0 py-0"
            @click="
              layer.locate_request_provider_account_id = a.value;
              $emit('layer-saved-step-2', layer);
              $emit('layer-saved-step-3', layer);
            "
          >
            <span
              :style="{
                color:
                  layer.locate_request_provider_account_id === a.value
                    ? 'white'
                    : 'primary',
              }"
            >
              {{ a.label }}
            </span>
          </v-list-item>
        </v-list>
        <v-list v-else outlined rounded width="fit-content" dense>
          <v-list-item>
            {{ selected811AccountLabel }}
          </v-list-item>
        </v-list>
      </div>
    </div>

    <v-expand-transition>
      <div v-show="showCreateLayerAnywayMessage">
        <v-alert
          color="primary"
          dark
          width="85%"
          class="mt-5 rounded-tl-lg rounded-tr-lg rounded-br-lg d-flex justify-space-between"
          v-show="showCreateLayerAnywayMessage"
        >
          Testing the connection to the layer has failed. Do you want to create
          the layer anyway?
        </v-alert>
      </div>
    </v-expand-transition>

    <div class="d-flex justify-end mb-5" v-if="showCreateLayerAnywayMessage">
      <v-list
        :style="{
          width: 'fit-content',
          'border-radius': '1%',
          border: '1px solid primary',
        }"
        class="py-0"
        dense
        v-if="createLayerAnyway === undefined"
      >
        <v-list-item
          color="primary"
          class="my-0 py-0"
          @click="
            createLayerAnyway = 'Yes';
            onStep2Click();
          "
        >
          <span
            :style="{
              color: createLayerAnyway === 'Yes' ? 'white' : 'primary',
            }"
          >
            Yes
          </span>
        </v-list-item>
        <v-list-item
          color="primary"
          class="my-0 py-0"
          @click="
            createLayerAnyway = 'No';
            showLayerNotCreated();
          "
        >
          <span
            :style="{
              color: createLayerAnyway === 'No' ? 'white' : 'primary',
            }"
          >
            No
          </span>
        </v-list-item>
      </v-list>
      <v-list v-else outlined rounded width="fit-content" dense>
        <v-list-item>
          {{ createLayerAnyway }}
        </v-list-item>
      </v-list>
    </div>

    <v-expand-transition>
      <div v-show="createLayerAnyway === 'No'">
        <v-alert
          color="primary"
          dark
          width="85%"
          class="mt-5 rounded-tl-lg rounded-tr-lg rounded-br-lg"
        >
          The layer was not created.
        </v-alert>
      </div>
    </v-expand-transition>

    <v-expand-transition>
      <div v-show="selectedServiceTypeLabel && serviceUrlSubmitted">
        <v-alert
          color="primary"
          dark
          width="85%"
          class="mt-5 rounded-tl-lg rounded-tr-lg rounded-br-lg"
        >
          Great. I will create the layer now...
        </v-alert>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import sleep from "@/mixins/sleep";
import { mdiSend } from "@mdi/js";
import SupportedUrlExampleDialog from "@/components/layers/create-layer-form/step-1-layer-type/SupportedUrlExampleDialog";
import LayerTypeDescriptionsDialog from "@/components/layers/create-layer-form/step-1-layer-type/LayerTypeDescriptionsDialog";
import BrowseArcGISServices from "@/components/layers/create-layer-form/step-1-layer-type/BrowseArcGISServices";
import { xml2js } from "xml-js";
import { TEST_CONNECTION_TIMEOUT } from "@/constants/layer";

const cancelTokenSource = axios.CancelToken.source();
const APIURL = process.env.VUE_APP_API_URL;
const serviceTypeOptions = [
  { label: "UtiliSync Layer", value: "U" },
  { label: "811 Tickets Layer", value: "L" },
  { label: "ArcGIS Service", value: "FS" },
  { label: "WMS Layer", value: "W" },
];

export default {
  name: "Step1LayerType",
  props: {
    layerObj: Object,
  },
  data() {
    return {
      serviceTypeOptions,
      layer: {},
      viewLayerTypeDescriptions: false,
      accounts811: [],
      showStep1: false,
      showStep2: false,
      showStep3: false,
      mdiSend,
      serviceUrlSubmitted: false,
      showSupportedUrlExampleDialog: false,
      showLayerTypeDescriptionsDialog: false,
      showCreateLayerAnywayMessage: false,
      createLayerAnyway: undefined,
      showBrowseArcGIS: false,
      layersForCreation: [],
      importingServices: false,
      testingLayer: false,
      manualInput: false,
    };
  },
  components: {
    SupportedUrlExampleDialog,
    LayerTypeDescriptionsDialog,
    BrowseArcGISServices,
  },
  computed: {
    selectedServiceTypeLabel() {
      if (this.layer.service_type === "F" || this.layer.service_type === "S") {
        return "ArcGIS Service";
      } else {
        return this.serviceTypeOptions.find(
          (o) => o.value === this.layer.service_type
        )?.label;
      }
    },
    account811Choices() {
      const accounts811Options = this.accounts811.map((a) => {
        const {
          locate_request_provider_account_id: locateRequestProviderAccountId,
          label,
        } = a;
        return {
          label,
          value: locateRequestProviderAccountId,
        };
      });

      const showAll = {
        label: "Show All",
        value: null,
      };
      return [...accounts811Options, showAll];
    },
    selected811AccountLabel() {
      return this.account811Choices.find(
        (a) => a.value === this.layer.locate_request_provider_account_id
      )?.label;
    },
    hasEsriToken() {
      return localStorage.getItem("esri_token") ? true : false;
    },
  },
  async beforeMount() {
    this.layer = cloneDeep(this.layerObj);
    await this.get811Accounts();
    await this.$nextTick();
    if (this.account811Choices.length === 1) {
      this.layer.locate_request_provider_account_id =
        this.account811Choices[0].value;
    }
    await sleep(500);
    this.showStep1 = true;
    await sleep(500);
    this.showStep2 = true;
    await sleep(500);
    this.showStep3 = true;
  },
  methods: {
    async showLayerNotCreated() {
      await sleep(2000);
      this.$emit("layer-not-created");
    },
    async testServiceUrlConnection() {
      const { service_url: serviceUrl } = this.layer;
      if (!serviceUrl) {
        return;
      }

      const { data } = await axios.get(serviceUrl, {
        params: {
          f: "pjson",
        },
      });
      this.layer.token_type = data.error ? "AGOL" : "NONE";
    },
    async testWmsLayerUrlConnection() {
      const { service_url: serviceUrl } = this.layer;
      if (!serviceUrl) {
        return;
      }

      this.testingLayer = true;
      try {
        const token = localStorage.getItem("esri_token");
        const timer = setTimeout(() => {
          cancelTokenSource.cancel();
          this.showCreateLayerAnywayMessage = true;
        }, TEST_CONNECTION_TIMEOUT);

        const { data } = await axios.get(serviceUrl, {
          params: {
            SERVICE: "WMS",
            REQUEST: "GetCapabilities",
          },
        });
        const wmsData = xml2js(data, { compact: true });
        clearTimeout(timer);
        if (wmsData?.WMS_Capabilities) {
          this.onStep2Click();
          this.testingLayer = false;
          this.$emit("wms-data-requested", wmsData);
          return;
        } else {
          const timer = setTimeout(() => {
            cancelTokenSource.cancel();
            this.showCreateLayerAnywayMessage = true;
          }, TEST_CONNECTION_TIMEOUT);
          const { data } = await axios.get(serviceUrl, {
            params: {
              SERVICE: "WMS",
              REQUEST: "GetCapabilities",
              token,
            },
          });
          const wmsData = xml2js(data, { compact: true });
          clearTimeout(timer);
          if (wmsData?.WMS_Capabilities) {
            this.onStep2Click();
            this.$emit("wms-data-requested", wmsData);
          } else {
            this.showCreateLayerAnywayMessage = true;
          }
        }
      } catch (error) {
        this.showCreateLayerAnywayMessage = true;
      } finally {
        this.testingLayer = false;
      }
    },
    async onKeyPress(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        this.testingLayer = true;
        if (["FS", "F", "S"].includes(this.layer.service_type)) {
          await this.testConnection();
        } else if (["W"].includes(this.layer.service_type)) {
          await this.testWmsLayerUrlConnection();
        }
        this.testingLayer = false;
      }
    },
    async testConnection() {
      this.layer.service_type = this.layer.service_url.includes("FeatureServer")
        ? "F"
        : "S";
      try {
        if (["FS", "F", "S"].includes(this.layer.service_type)) {
          await this.testServiceUrlConnection();
        }
        this.onStep2Click();
      } catch (error) {
        this.showCreateLayerAnywayMessage = true;
      }
    },
    onStep1Click(value) {
      this.layer.service_type = value;
      this.$emit("layer-saved-step-1", this.layer);

      if (this.layer.service_type === "U") {
        this.$emit("layer-saved-step-2", this.layer);
        this.$emit("layer-saved-step-3", this.layer);
      } else if (
        this.accounts811.length <= 1 &&
        this.layer.service_type === "L"
      ) {
        const [account] = this.account811Choices;
        this.layer.locate_request_provider_account_id = account.value;
        this.$emit("layer-saved-step-2", this.layer);
        this.$emit("layer-saved-step-3", this.layer);
      }
    },
    async onStep2Click() {
      const success = await this.$refs.serviceUrlForm.validate();
      if (!success) {
        return;
      }
      this.manualInput = true;
      this.serviceUrlSubmitted = true;
      this.$emit("layer-saved-step-2", this.layer);
      if (
        this.account811Choices.length <= 1 ||
        this.layer.service_type !== "L"
      ) {
        this.layer.locate_request_provider_account_id = null;
        this.$emit("layer-saved-step-3", this.layer);
      }
    },
    async get811Accounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts/all`);
      this.accounts811 = results;
      if (this.accounts811.length === 0) {
        this.layer.locate_request_provider_account_id = null;
        this.$emit("layer-saved-step-1", this.layer);
      }
    },
  },
  watch: {
    layerObj: {
      deep: true,
      handler(val) {
        this.layer = {
          ...this.layer,
          ...val,
        };
      },
    },
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 8px !important;
}
</style>
