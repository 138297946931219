<template>
  <v-dialog
    :value="showUtiliSyncFieldsDialog"
    max-width="600px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" ref="toolbar" class="elevation-0">
        <v-toolbar-title>UtiliSync Fields</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-utilisync-fields-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="py-5"
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
      >
        <p>
          UtiliSync fields are used to store information about site, such as
          project name, address and status.
        </p>
        <div class="d-flex justify-end">
          <v-btn
            text
            color="primary"
            @click="showAddUtiliSyncFieldsDialog = true"
          >
            + Add UtiliSync Field
          </v-btn>
        </div>

        <v-card>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="value">
                    <p class="caption">Field Name</p>
                  </th>
                  <th>
                    <p class="caption">Type</p>
                  </th>
                  <th>
                    <p class="caption">Field Options</p>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody v-if="gisDataFields.length > 0">
                <tr
                  v-for="(field, index) of gisDataFields"
                  :key="field.gis_data_field_id"
                >
                  <td class="white">{{ field.name }}</td>
                  <td class="white">{{ field.type }}</td>
                  <td class="white">
                    {{ field.gis_data_field_options.length > 0 ? "Yes" : "No" }}
                  </td>
                  <td class="white d-flex justify-end align-center">
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="primary">{{ mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="
                            showEditUtiliSyncFieldDialog = true;
                            selectedUtiliSyncField = field;
                            selectedUtiliSyncFieldIndex = index;
                          "
                        >
                          <v-list-item-title
                            class="primary--text text--lighten-1"
                          >
                            <v-icon color="primary">{{ mdiPencil }}</v-icon>
                            Edit
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteGisDataField(field)">
                          <v-list-item-title
                            class="primary--text text--lighten-1"
                          >
                            <v-icon color="primary">{{ mdiDelete }}</v-icon>
                            Delete
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4" class="caption">
                    <div class="d-flex justify-center">
                      No UtiliSync Fields added yet.
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <AddUtiliSyncFieldsDialog
          v-if="showAddUtiliSyncFieldsDialog"
          :showAddUtiliSyncFieldsDialog="showAddUtiliSyncFieldsDialog"
          :mapServiceId="mapServiceId"
          @add-utilisync-fields-dialog-close="
            showAddUtiliSyncFieldsDialog = false;
            $emit('add-utilisync-fields-dialog-close');
          "
          @add-utilisync-fields-dialog-added="
            showAddUtiliSyncFieldsDialog = false;
            getGisDataFields();
          "
          @field-name-set="checkFieldName"
        />

        <EditUtiliSyncFieldDialog
          v-if="showEditUtiliSyncFieldDialog"
          :showEditUtiliSyncFieldDialog="showEditUtiliSyncFieldDialog"
          :mapServiceId="mapServiceId"
          @edit-utilisync-field-dialog-close="
            showEditUtiliSyncFieldDialog = false
          "
          @edit-utilisync-fields-dialog-submitted="
            onEditUtilisyncFieldSubmitted
          "
          :selectedUtiliSyncField="selectedUtiliSyncField"
          :selectedUtiliSyncFieldIndex="selectedUtiliSyncFieldIndex"
        />

        <DuplicateFieldNameDialog
          :showDuplicateFieldNameDialog="showDuplicateFieldNameDialog"
          @duplicate-field-mame-dialog-close="
            showDuplicateFieldNameDialog = false
          "
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-5" ref="cardAction">
        <v-btn
          dark
          color="primary"
          @click="$emit('add-utilisync-fields-dialog-close')"
        >
          Return to Utilibot
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddUtiliSyncFieldsDialog from "@/components/layers/create-layer-form/step-3-add-utilisync-fields/utilisync-fields-dialog/AddUtiliSyncFieldsDialog";
import EditUtiliSyncFieldDialog from "@/components/layers/create-layer-form/step-3-add-utilisync-fields/utilisync-fields-dialog/EditUtiliSyncFieldDialog";
import DuplicateFieldNameDialog from "@/components/layers/create-layer-form/step-3-add-utilisync-fields/utilisync-fields-dialog/DuplicateFieldNameDialog";
import axios from "axios";
import { mdiDelete, mdiDotsVertical, mdiPencil } from "@mdi/js";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "UtiliSyncFieldsDialog",
  props: {
    showUtiliSyncFieldsDialog: Boolean,
    mapServiceId: String,
  },
  components: {
    AddUtiliSyncFieldsDialog,
    DuplicateFieldNameDialog,
    EditUtiliSyncFieldDialog,
  },
  mixins: [contentHeightMixin],
  data() {
    return {
      showAddUtiliSyncFieldsDialog: false,
      showEditUtiliSyncFieldDialog: false,
      gisDataFields: [],
      mdiDelete,
      mdiDotsVertical,
      mdiPencil,
      showDuplicateFieldNameDialog: false,
      selectedUtiliSyncField: {},
      selectedUtiliSyncFieldIndex: undefined,
    };
  },
  methods: {
    onEditUtilisyncFieldSubmitted(index, utiliSyncField) {
      this.showEditUtiliSyncFieldDialog = false;
      this.$set(this.gisDataFields, index, utiliSyncField);
      this.setUtiliSyncFieldsUpdated(true);
    },
    checkFieldName(fieldName) {
      const fieldNameAlreadyExists = this.gisDataFields.find(
        (f) => f.name === fieldName
      );
      if (fieldNameAlreadyExists) {
        this.showDuplicateFieldNameDialog = true;
      }
    },
    async getGisDataFields() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: mapServiceId,
        },
      });
      this.gisDataFields = results;
      this.setUtiliSyncFieldsUpdated(true);
      this.$emit("get-gis-data-fields", this.gisDataFields.length);
    },
    async deleteGisDataField(field) {
      await axios.delete(
        `${APIURL}/gis_data_fields/${field.gis_data_field_id}`
      );
      await this.getGisDataFields();
    },
    ...mapMutations(["setUtiliSyncFieldsUpdated"]),
  },
  beforeMount() {
    this.getGisDataFields();
  },
};
</script>
