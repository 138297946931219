<template>
  <v-dialog
    v-model="showAddUserDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" ref="toolbar">
        <v-toolbar-title>Add User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-user-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pl-8 pr-8"
        :style="{
          'background-color': '#f1f2f1',
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : 'auto',
          'overflow-y': 'auto',
          'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
        }"
        ref="userBox"
      >
        <div ref="userInnerBox" class="py-5">
          <Step1UserForm @step-1-finished="onStep1Finished" />
          <Step2UserForm
            :userGroup="userGroup"
            v-if="step1Finished"
            @step-2-finished="onStep2Finished"
          />
          <Step3UserFormEmail
            :savedUser="user"
            v-if="step1Finished && step2Finished"
            @step-3-finished="onStep3Finished"
          />
          <Step4UserFormCellPhone
            :savedUser="user"
            v-if="step1Finished && step2Finished && step3Finished && showStep4"
            @step-4-finished="onStep4Finished"
          />
          <Step5UserFormTimeZone
            v-if="
              step1Finished && step2Finished && step3Finished && step4Finished
            "
            @step-5-finished="onStep5Finished"
          />
          <Step6UserFormAdmin
            :savedUser="user"
            v-if="
              step1Finished &&
              step2Finished &&
              step3Finished &&
              step4Finished &&
              step5Finished
            "
            @step-6-finished="onStep6Finished"
          />
          <Step7UserFormLocator
            :savedUser="user"
            v-if="showStep7"
            @step-7-finished="onStep7Finished"
          />
          <Step8UserFormUserType
            v-if="showStep8"
            :savedUser="user"
            :userGroup="userGroup"
            @step-8-finished="onStep8Finished"
          />
          <Step9UserFormMap
            :savedUser="user"
            v-if="
              step1Finished &&
              step2Finished &&
              step3Finished &&
              step4Finished &&
              step5Finished &&
              step6Finished &&
              ((showStep7 && step7Finished) || !showStep7) &&
              ((showStep8 && step8Finished) || !showStep8)
            "
            @step-9-finished="onStep9Finished"
          />
          <Step10UserFormApp
            v-if="step9Finished && computedAppChoices.length > 1"
            :savedUser="user"
            :appForPasswordCreationChoices="computedAppChoices"
            @step-10-finished="onStep10Finished($event)"
          ></Step10UserFormApp>

          <Step11UserDepartment
            v-if="step10Finished && departments.length > 0"
            :savedUser="user"
            :departmentChoices="departmentChoices"
            @step-11-finished="onStep11Finished($event)"
          />

          <v-expand-transition>
            <v-alert
              color="primary"
              dark
              width="85%"
              class="rounded-tl-lg rounded-tr-lg rounded-br-lg mb-5"
              v-show="
                step1Finished &&
                step2Finished &&
                step3Finished &&
                step4Finished &&
                step5Finished &&
                step6Finished &&
                ((showStep7 && step7Finished) || !showStep7) &&
                ((showStep8 && step8Finished) || !showStep8) &&
                step9Finished &&
                step10Finished &&
                (step11Finished || departments.length === 0)
              "
            >
              Perfect. I have everything I need to create this user. Just give
              me a minute to do that.
            </v-alert>
          </v-expand-transition>

          <v-expand-transition>
            <v-alert
              color="primary"
              dark
              width="85%"
              class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
              v-show="
                step1Finished &&
                step2Finished &&
                step3Finished &&
                step4Finished &&
                step5Finished &&
                step6Finished &&
                ((showStep7 && step7Finished) || !showStep7) &&
                ((showStep8 && step8Finished) || !showStep8) &&
                step9Finished &&
                step10Finished &&
                step11Finished
              "
            >
              <template v-if="userCreated">
                All set! The new user has been created. You can see
                {{ user.firstName }}'s user details and set
                {{ user.firstName }}'s notifications by click on that row in the
                users table. Thanks for coming by today. It's been a pleasure
                working with you!
              </template>
              <template v-else>
                <v-progress-circular indeterminate color="white">
                </v-progress-circular>
              </template>
            </v-alert>
          </v-expand-transition>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Step1UserForm from "@/components/organization/users/add-user-dialog/Step1UserForm";
import Step2UserForm from "@/components/organization/users/add-user-dialog/Step2UserForm";
import Step3UserFormEmail from "@/components/organization/users/add-user-dialog/Step3UserFormEmail";
import Step4UserFormCellPhone from "@/components/organization/users/add-user-dialog/Step4UserFormCellPhone";
import Step5UserFormTimeZone from "@/components/organization/users/add-user-dialog/Step5UserFormTimeZone";
import Step6UserFormAdmin from "@/components/organization/users/add-user-dialog/Step6UserFormAdmin";
import Step7UserFormLocator from "@/components/organization/users/add-user-dialog/Step7UserFormLocator";
import Step8UserFormUserType from "@/components/organization/users/add-user-dialog/Step8UserFormUserType";
import Step9UserFormMap from "@/components/organization/users/add-user-dialog/Step9UserFormMap";
import Step10UserFormApp from "@/components/organization/users/add-user-dialog/Step10UserFormApp";
import Step11UserDepartment from "@/components/organization/users/add-user-dialog/Step11UserDepartment";
import axios from "axios";
import sleep from "@/mixins/sleep";
import contentHeightMixin from "@/mixins/contentHeightMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddUserDialog",
  props: {
    showAddUserDialog: Boolean,
    userGroup: Object,
  },
  components: {
    Step1UserForm,
    Step2UserForm,
    Step3UserFormEmail,
    Step4UserFormCellPhone,
    Step5UserFormTimeZone,
    Step6UserFormAdmin,
    Step7UserFormLocator,
    Step8UserFormUserType,
    Step9UserFormMap,
    Step10UserFormApp,
    Step11UserDepartment,
  },
  mixins: [contentHeightMixin],
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        username: "",
        esriUsername: "",
        email: "",
        cellPhone: "",
        timeZone: "",
        isAdmin: false,
        isLocator: false,
        isViewOnlyUser: false,
        isPowerUser: false,
        isStandardUser: false,
        mapIdSelected: undefined,
      },
      step1Finished: false,
      step2Finished: false,
      step3Finished: false,
      step4Finished: false,
      step5Finished: false,
      step6Finished: false,
      step7Finished: false,
      step8Finished: false,
      step9Finished: false,
      step10Finished: false,
      step11Finished: false,
      userCreated: false,
      auth: {},
      showStep7: false,
      showStep8: false,
      showStep9: false,
      users: [],
      appForPasswordCreationChoices: [],
      appForPasswordCreation: undefined,
      departments: [],
      selectedDepartmentIds: [],
    };
  },
  computed: {
    showStep4() {
      return this.userGroup?.sms_enabled !== false;
    },
    numStandardUsersAvailable() {
      const { number_of_standard_users: numStandardUsers } = this.userGroup;
      return (
        (numStandardUsers ?? 0) -
        this.users.filter((u) => u.is_standard_user && u.is_active).length
      );
    },
    numPowerUsersAvailable() {
      const { number_of_power_users: numPowerUsers } = this.userGroup;
      return (
        numPowerUsers -
        this.users.filter((u) => u.is_power_user && u.is_active).length
      );
    },
    computedAppChoices() {
      return this.appForPasswordCreationChoices;
    },
    departmentChoices() {
      return this.departments.map((d) => {
        const { department_id: value, name: label } = d;
        return { value, label };
      });
    },
  },
  methods: {
    async getDepartments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/departments`);
      this.departments = results;
    },
    onStep1Finished({ firstName, lastName, username }) {
      this.user = { ...this.user, firstName, lastName, username };
      this.step1Finished = true;
    },
    onStep2Finished({ esriUsername }) {
      this.user = { ...this.user, esriUsername };
      this.step2Finished = true;
    },
    onStep3Finished({ email }) {
      this.user = { ...this.user, email };
      this.step3Finished = true;

      // Skip step 4 if SMS is not enabled for the user group
      if (this.userGroup?.sms_enabled === false) {
        this.step4Finished = true;
        this.user = { ...this.user, cellPhone: null };
      }
    },
    onStep4Finished({ cellPhone }) {
      this.user = { ...this.user, cellPhone };
      this.step4Finished = true;
    },
    onStep5Finished({ timeZone }) {
      this.user = { ...this.user, timeZone };
      this.step5Finished = true;
    },
    async onStep6Finished({ isAdmin }) {
      this.user = { ...this.user, isAdmin };
      await this.getUserGroupUsers();
      const { numStandardUsersAvailable, numPowerUsersAvailable } = this;
      this.step6Finished = true;
      if (this.auth?.organization?.access_811_tms_app) {
        this.showStep7 = true;
      } else if (this.auth?.organization?.access_field_reports_app) {
        if (numStandardUsersAvailable > 0 || numPowerUsersAvailable > 0) {
          this.showStep8 = true;
        } else {
          this.user = {
            ...this.user,
            isViewOnlyUser: true,
            isPowerUser: false,
            isStandardUser: false,
          };
          this.showStep9 = true;
        }
      } else {
        this.showStep9 = true;
      }
    },
    onStep7Finished({ isLocator }) {
      this.user = { ...this.user, isLocator };
      this.step7Finished = true;
      if (this.auth?.organization?.access_field_reports_app) {
        this.showStep8 = true;
      } else {
        this.showStep9 = true;
      }
    },
    onStep8Finished({ isPowerUser, isViewOnlyUser, isStandardUser }) {
      this.user = { ...this.user, isPowerUser, isViewOnlyUser, isStandardUser };
      this.step8Finished = true;
    },
    async onStep9Finished({ mapIdSelected }) {
      this.user = { ...this.user, mapIdSelected };
      await this.checkAppForPasswordCreationChoices();
      this.step9Finished = true;
    },
    async onStep10Finished(appForPasswordCreation) {
      this.step10Finished = true;
      this.appForPasswordCreation = appForPasswordCreation;
      if (this.departments.length === 0) {
        this.step11Finished = true;
        const newUser = await this.createUser(this.appForPasswordCreation);
        await this.linkDepartmentsToUser(newUser?.user_id);
        this.$emit("user-created");
      }
    },
    async onStep11Finished(selectedDepartmentIds) {
      this.step11Finished = true;
      this.selectedDepartmentIds = selectedDepartmentIds;
      const newUser = await this.createUser(this.appForPasswordCreation);
      await this.linkDepartmentsToUser(newUser?.user_id);
      this.$emit("user-created");
    },
    async checkAppForPasswordCreationChoices() {
      if (this.userGroup.access_811_tms_app) {
        this.appForPasswordCreationChoices.push({
          label: "UtiliSync 811",
          value: "tms",
        });
      }
      if (this.userGroup.access_field_reports_app) {
        this.appForPasswordCreationChoices.push({
          label: "UtiliSync Inspect",
          value: "inspect",
        });
      }
      if (this.user.isAdmin) {
        this.appForPasswordCreationChoices.push({
          label: "UtiliSync Admin",
          value: "user-admin",
        });
      }
      if (this.appForPasswordCreationChoices.length == 1) {
        this.onStep10Finished(this.appForPasswordCreationChoices[0]);
      }
    },
    async getUserGroupUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results;
    },
    async createUser(appForPasswordCreation) {
      let {
        firstName,
        lastName,
        username,
        esriUsername,
        email,
        cellPhone,
        isAdmin,
        isLocator,
        isPowerUser,
        isViewOnlyUser,
        isStandardUser,
        mapIdSelected,
        timeZone,
      } = this.user;

      if (!isPowerUser && !isStandardUser && !isViewOnlyUser) {
        isViewOnlyUser = true;
      }

      const payload = {
        username,
        f_name: firstName,
        l_name: lastName,
        email,
        is_gis_admin: isAdmin,
        is_power_user: isPowerUser,
        is_view_only_user: isViewOnlyUser,
        is_standard_user: isStandardUser,
        locate_requests_user: isLocator,
        time_zone: timeZone,
        last_map_opened: mapIdSelected,
        sms_notification: cellPhone || null,
        esri_username: esriUsername || null,
        hide_tutorial_menu: false,
        is_active: true,
        app: appForPasswordCreation,
        manage_users: isAdmin,
        manage_maps: isAdmin,
        manage_layers: isAdmin,
        manage_forms: isAdmin,
        manage_811_codes: isAdmin,
      };
      const {
        data: {
          results: { user },
        },
      } = await axios.post(`${APIURL}/users`, payload);
      this.userCreated = true;
      await sleep(3000);
      return user;
    },
    async linkDepartmentsToUser(userId) {
      const userDepartmentsToInsert = this.departments
        .filter((d) => this.selectedDepartmentIds.includes(d.department_id))
        .map((d) => {
          const { department_id: departmentId } = d;
          return {
            user_id: userId,
            department_id: departmentId,
          };
        });
      await axios.post(`${APIURL}/user_departments/batch`, {
        user_departments: userDepartmentsToInsert,
      });
    },
    async scrollToBottom() {
      await this.$nextTick();
      if (this.$refs.userBox) {
        this.$refs.userBox.scrollTo(0, this.$refs.userBox.scrollHeight);
      }
    },
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        this.scrollToBottom();
      });
    });
    resizeObserver.observe(this.$refs.userInnerBox);
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.getDepartments();
  },
};
</script>
