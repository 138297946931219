<template>
  <v-dialog v-model="showBrowseArcGIS" max-width="600px" persistent>
    <v-card style="background-color: #f1f2f1">
      <v-toolbar dark color="primary" class="elevation-0">
        <v-toolbar-title>Browse ArcGIS Services</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('browse-arcgis-services-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="pt-4 pb-2 px-4"
        :style="{
          'background-color': '#f1f2f1',
          height: 'auto',
          'overflow-y': 'auto',
        }"
      >
        <v-data-table
          :headers="headers"
          :items="computedServices"
          item-key="id"
          fixed-header
          height="55vh"
          dense
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          @click:row="handleRowClick"
          @item-expanded="handleRowClick"
          :items-per-page="services.length"
          hide-default-footer
          class="cursor-pointer"
        >
          <template v-slot:top>
            <form @submit.prevent="">
              <div class="d-flex justify-space-between align-end gap px-4 mb-2">
                <v-text-field
                  v-model="search"
                  label="Search"
                  hide-details="auto"
                  color="primary"
                  name="search"
                >
                  <template v-slot:append>
                    <v-icon>{{ mdiMagnify }}</v-icon>
                  </template>
                </v-text-field>

                <div>
                  <v-btn
                    color="primary"
                    @click="
                      performSearch = true;
                      getArcGISServices();
                    "
                    type="submit"
                    id="addLayerBtn"
                  >
                    Search
                  </v-btn>
                </div>
              </div>
            </form>
            <div class="px-4">
              <v-chip class="mb-2" @click="toggleServicesShown">
                {{ servicesShown }}
                <v-icon class="ml-2" small>{{ mdiSyncCircle }}</v-icon>
              </v-chip>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="mt-2" v-if="item.type === 'Feature Service'">
                <div>
                  Select the feature services to create from the "{{
                    item.title
                  }}" feature layer.
                </div>
                <div v-if="computedSelectedServices.length > 0">
                  <v-checkbox
                    label="Select All"
                    v-model="selectAll"
                    @click="selectAllServices"
                    class="font-weight-bold"
                    hide-details
                  ></v-checkbox>
                  <v-divider class="mt-4"></v-divider>
                  <v-checkbox
                    v-for="service in computedSelectedServices"
                    :key="service.id"
                    v-model="service.create"
                    :label="service.name"
                    hide-details
                  ></v-checkbox>
                </div>
                <div v-else-if="gettingFeatureServices" class="mt-3">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-else class="mt-2 font-weight-medium">
                  No services available for import
                </div>
              </div>
              <div class="mt-2" v-else>
                Import the "{{ item.title }}" map service?
              </div>

              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  class="my-2"
                  @click="importSelectedServices()"
                  color="primary"
                  :disabled="selectedServices.length === 0 || disableImport"
                  >Import</v-btn
                >
              </div>
            </td>
          </template>
        </v-data-table>
        <div class="d-flex justify-center pt-2">
          <v-btn
            :disabled="nextStart === -1 || loading"
            color="primary"
            @click="getArcGISServices"
            >Load More</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mdiSyncCircle, mdiMagnify } from "@mdi/js";

const headers = [
  {
    text: "Name",
    align: "start",
    sortable: true,
    value: "title",
    width: 300,
  },
  {
    text: "Type",
    align: "start",
    sortable: false,
    value: "type",
    minWidth: 150,
  },
];

export default {
  name: "ShowBrowseArcGIS",
  props: {
    showBrowseArcGIS: Boolean,
  },
  directives: {},
  data() {
    return {
      mdiSyncCircle,
      mdiMagnify,
      headers,
      services: [],
      expanded: [],
      selectedServices: [],
      mapService: true,
      featureService: true,
      nextStart: 1,
      loading: false,
      gettingFeatureServices: false,
      search: "",
      performSearch: false,
      selectAll: false,
    };
  },
  computed: {
    servicesShown() {
      if (this.featureService && this.mapService) {
        return "Feature and Map Services";
      } else if (this.featureService) {
        return "Feature Services";
      } else {
        return "Map Services";
      }
    },
    serviceTypes() {
      if (this.featureService && this.mapService) {
        return '"Feature Service" OR "Map Services"';
      } else if (this.featureService) {
        return '"Feature Service"';
      } else {
        return '"Map Service"';
      }
    },
    computedServices() {
      if (this.featureService && this.mapService) {
        return this.services;
      } else if (this.featureService) {
        return this.services.filter(
          (service) => service.type === "Feature Service"
        );
      } else {
        return this.services.filter(
          (service) => service.type === "Map Service"
        );
      }
    },
    computedSelectedServices() {
      return this.selectedServices;
    },
    disableImport() {
      if (this.expanded[0].type === "Feature Service") {
        return (
          this.selectedServices.filter((service) => {
            return service.create;
          }).length === 0
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleServicesShown() {
      if (this.featureService && this.mapService) {
        this.mapService = false;
      } else if (this.featureService) {
        this.mapService = true;
        this.featureService = false;
      } else {
        this.mapService = true;
        this.featureService = true;
      }
      this.services = [];
      this.nextStart = 1;
      this.getArcGISServices();
    },
    async getArcGISServices() {
      this.loading = true;
      const { esri_org_id: esriOrgId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const portalURL = localStorage.getItem("portal-url");
      const token = localStorage.getItem("esri_token");

      let query = "";
      if (this.performSearch) {
        this.services = [];
        this.nextStart = 1;
        query = `${this.search} `;
      }
      if (esriOrgId) {
        query += `orgid:${esriOrgId} `;
      }
      query += `(type:(${this.serviceTypes}) -typekeywords:"Table") `;

      const { data } = await axios.get(`${portalURL}/sharing/rest/search`, {
        params: {
          num: 100,
          start: this.nextStart,
          sortField: "title",
          sortOrder: "asc",
          q: query,
          f: "json",
          token: token,
        },
      });

      this.nextStart = data.nextStart;
      this.services.push(...data.results);
      this.loading = false;
    },

    async handleRowClick(data) {
      let item = data;
      if (data.item) {
        item = data.item;
      }
      if (this.expanded.includes(item)) {
        this.expanded = [];
        this.selectedServices = [];
      } else {
        this.expanded = [item];
        if (item.type === "Map Service") {
          this.selectedServices = [item];
        } else {
          await this.getFeatureLayerServices();
        }
      }
    },
    async getFeatureLayerServices() {
      this.gettingFeatureServices = true;
      this.selectedServices = [];
      const serviceURL = this.expanded[0].url;
      const accessType = this.expanded[0].access;
      const { data } = await axios.get(serviceURL, {
        params: {
          f: "pjson",
          token: localStorage.getItem("esri_token"),
        },
      });
      if (data.layers) {
        this.selectedServices = data.layers.map((layer) => ({
          ...layer,
          create: false,
          url: `${serviceURL}/${layer.id}`,
          access: accessType,
        }));
      }
      this.gettingFeatureServices = false;
    },
    importSelectedServices() {
      this.selectedServices = this.selectedServices.filter((service) => {
        return service.create || service.type === "Map Service";
      });
      this.$emit("browse-arcgis-services-submitted", this.selectedServices);
    },
    selectAllServices() {
      if (this.selectAll) {
        this.selectedServices = this.selectedServices.map((s) => {
          s.create = true;
          return s;
        });
      } else {
        this.selectedServices = this.selectedServices.map((s) => {
          s.create = false;
          return s;
        });
      }
    },
  },
  beforeMount() {
    this.getArcGISServices();
  },
};
</script>

<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}

.gap {
  gap: 10px;
}
</style>
