<template>
  <v-dialog
    :value="showExitLayerCreationDialog"
    max-width="550px"
    class="mx-auto"
  >
    <v-card>
      <v-list-item>
        <v-list-item-content class="pl-3">
          <div class="text-h5 py-3">Exit Layer Creation UtiliBot?</div>
          If you exit the Layer Creation UtiliBot now you will lose the progress
          you've made in the creation of this layer. Are you sure you want to
          exit?
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          dark
          text
          color="primary"
          @click="$emit('exit-layer-creation-dialog-close')"
        >
          Return to Utilibot
        </v-btn>
        <v-btn
          dark
          color="primary"
          @click="
            $emit('create-layer-form-close');
            deleteLayer();
          "
        >
          Exit Utilibot
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "ExitLayerCreationDialog",
  props: {
    showExitLayerCreationDialog: Boolean,
    mapServiceId: String,
  },
  methods: {
    async deleteLayer() {
      const { mapServiceId } = this;
      if (!mapServiceId) {
        return;
      }
      const {
        data: {
          results: { renderer },
        },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      const { renderer_id: rendererId } = renderer;
      if (rendererId) {
        await axios.delete(`${APIURL}/renderer_symbol/all/${rendererId}`);
      }
      const {
        data: { results: gisDataFields },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: mapServiceId,
        },
      });
      const gisDataFieldOptions = gisDataFields
        .map((f) => f.gis_data_field_options)
        .flat();
      await Promise.all(
        gisDataFieldOptions.map((o) =>
          axios.delete(
            `${APIURL}/gis_data_field_options/${o.gis_data_field_option_id}`
          )
        )
      );
      await Promise.all(
        gisDataFields.map((f) =>
          axios.delete(`${APIURL}/gis_data_fields/${f.gis_data_field_id}`)
        )
      );
      await axios.delete(`${APIURL}/map_services/${mapServiceId}`);
    },
  },
};
</script>
