var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLayersToShowOnMap,
                  expression: "showLayersToShowOnMap",
                },
              ],
              staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
              attrs: { color: "primary", dark: "", width: "85%" },
            },
            [_vm._v(" Layers to show on map: ")]
          ),
        ],
        1
      ),
      _vm.showLayersToShowOnMap
        ? _c("div", { staticClass: "d-flex justify-end mb-5" }, [
            _c(
              "section",
              [
                _c(
                  "v-list",
                  {
                    staticClass: "py-0",
                    style: {
                      width: "fit-content",
                      "border-radius": "1%",
                      border: "1px solid primary",
                    },
                    attrs: { dense: "" },
                  },
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-checkbox", {
                          staticClass: "py-0 my-0",
                          attrs: {
                            "input-value":
                              _vm.selectedSublayerIds.length ===
                              _vm.sublayerChoices.length,
                            dense: "",
                            "hide-details": "",
                          },
                          on: { change: _vm.onSelectAllChange },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [_c("b", [_vm._v("Select All")])]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3455379687
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._l(_vm.sublayerChoices, function (s) {
                      return _c(
                        "v-list-item",
                        { key: s.name, staticClass: "d-flex align-center" },
                        [
                          _c("v-checkbox", {
                            staticClass: "py-0 my-0",
                            attrs: {
                              value: s.name,
                              dense: "",
                              "hide-details": "",
                              name: "selectedSublayerIds",
                            },
                            model: {
                              value: _vm.selectedSublayerIds,
                              callback: function ($$v) {
                                _vm.selectedSublayerIds = $$v
                              },
                              expression: "selectedSublayerIds",
                            },
                          }),
                          _c("section", [_vm._v(" " + _vm._s(s.title) + " ")]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "section",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v("Next")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.submitted
        ? _c(
            "v-expand-transition",
            [
              _c(
                "v-alert",
                {
                  staticClass: "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                  attrs: { color: "primary", dark: "", width: "85%" },
                },
                [
                  _vm._v(
                    ' Done! The "' +
                      _vm._s(_vm.layerObj.service_name) +
                      '" layer has been created '
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }