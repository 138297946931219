<template>
  <v-dialog
    :value="showSupportedUrlExampleDialog"
    max-width="600px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="primary" class="elevation-0">
        <div>
          <v-toolbar-title>Service URL Formatting Example</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('supported-url-dialog-close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-5" style="background-color: #f1f2f1">
        <p class="font-weight-medium">For Feature Services:</p>
        <template>
          <p>
            https://&lt;server_name&gt;/arcgis/rest/services/&lt;layer_name&gt;/FeatureServer
          </p>
          <p>Be sure the url ends with “/FeatureServer/&lt;id&gt;".</p>
        </template>
        <br />
        <p class="font-weight-medium">For Map Services:</p>
        <template>
          <p>
            https://&lt;server_name&gt;/arcgis/rest/services/&lt;layer_name&gt;/MapServer
          </p>
          <p>
            Be sure the url ends with “/MapServer”. Do not include a layer ID
            (e.g. “/MapServer/3”).
          </p>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SupportedUrlExampleDialog",
  props: {
    showSupportedUrlExampleDialog: Boolean,
    serviceType: String,
  },
};
</script>
