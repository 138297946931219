<template>
  <div>
    <p class="py-0 mb-2 mt-4">Notifications</p>

    <v-card>
      <v-card-text class="pa-0 mx-0">
        <div class="d-flex justify-end px-2 pt-2">
          <v-btn variant="text" color="primary" @click="testPushNotification"
            >Test Push Notification
          </v-btn>
        </div>

        <v-simple-table id="userNotificationSettings">
          <thead>
            <tr>
              <th>Notification</th>
              <th>In-App</th>
              <th>Email</th>
              <th>Text</th>
              <th>
                Push
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1"
                      color="primary"
                      >mdi-information</v-icon
                    >
                  </template>

                  <span style="max-width: 175px; display: block"
                    >Users must be subscribed to push notifications and
                    logged-in to the app(s) on each device they want to receive
                    notifications on.</span
                  >
                </v-tooltip>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="n of userNotificationSettings"
              :key="n.notification_type"
              class="py-0 my-0"
            >
              <td>
                {{ n.notification_type | capitalize }}
              </td>
              <td>
                <v-checkbox
                  v-if="n.notification_type !== 'ticket_assigned'"
                  v-model="n.in_app"
                  @change="saveUserNotificationSettings"
                  :disabled="!canManageUsers"
                >
                </v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="n.email"
                  @change="saveUserNotificationSettings"
                  :disabled="!canManageUsers"
                >
                </v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="n.sms"
                  @change="saveUserNotificationSettings"
                  :disabled="!canManageUsers"
                >
                </v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="n.push"
                  @change="saveUserNotificationSettings"
                  :disabled="!canManageUsers"
                >
                </v-checkbox>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "NotificationsForm",
  props: {
    selectedUser: Object,
    inspectAccess: Boolean,
    tmsAccess: Boolean,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      userNotificationSettings: [],
    };
  },
  beforeMount() {
    this.getUserNotificationSettings();
  },
  methods: {
    async getUserNotificationSettings() {
      const {
        data: { results },
      } = await axios.get(
        `${APIURL}/user_notification_settings/${this.selectedUser.user_id}`
      );
      let filteredResults = [];
      if (this.inspectAccess) {
        filteredResults = results.filter((setting) =>
          ["daily_task_summary", "new_task_assigned"].includes(
            setting.notification_type
          )
        );
      }
      if (this.tmsAccess) {
        filteredResults = filteredResults.concat(
          results.filter(
            (setting) => setting.notification_type === "ticket_assigned"
          )
        );
      }
      this.userNotificationSettings = filteredResults;
    },
    async saveUserNotificationSettings() {
      const { userNotificationSettings } = this;
      const payload = {
        notification_settings: userNotificationSettings,
      };
      await axios.put(
        `${APIURL}/user_notification_settings/${this.selectedUser.user_id}`,
        payload
      );
    },
    async testPushNotification() {
      await axios.post(
        `${APIURL}/user_notification_settings/test_push_notification/${this.selectedUser.user_id}`
      );
    },
  },
};
</script>
