var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", persistent: "" },
      model: {
        value: _vm.showBrowseArcGIS,
        callback: function ($$v) {
          _vm.showBrowseArcGIS = $$v
        },
        expression: "showBrowseArcGIS",
      },
    },
    [
      _c(
        "v-card",
        { staticStyle: { "background-color": "#f1f2f1" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Browse ArcGIS Services")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("browse-arcgis-services-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "pt-4 pb-2 px-4",
              style: {
                "background-color": "#f1f2f1",
                height: "auto",
                "overflow-y": "auto",
              },
            },
            [
              _c("v-data-table", {
                staticClass: "cursor-pointer",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.computedServices,
                  "item-key": "id",
                  "fixed-header": "",
                  height: "55vh",
                  dense: "",
                  "single-expand": true,
                  expanded: _vm.expanded,
                  "show-expand": "",
                  "items-per-page": _vm.services.length,
                  "hide-default-footer": "",
                },
                on: {
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                  "click:row": _vm.handleRowClick,
                  "item-expanded": _vm.handleRowClick,
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between align-end gap px-4 mb-2",
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Search",
                                    "hide-details": "auto",
                                    color: "primary",
                                    name: "search",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiMagnify)),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          type: "submit",
                                          id: "addLayerBtn",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.performSearch = true
                                            _vm.getArcGISServices()
                                          },
                                        },
                                      },
                                      [_vm._v(" Search ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "px-4" },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass: "mb-2",
                                on: { click: _vm.toggleServicesShown },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.servicesShown) + " "),
                                _c(
                                  "v-icon",
                                  { staticClass: "ml-2", attrs: { small: "" } },
                                  [_vm._v(_vm._s(_vm.mdiSyncCircle))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "expanded-item",
                    fn: function ({ headers, item }) {
                      return [
                        _c("td", { attrs: { colspan: headers.length } }, [
                          item.type === "Feature Service"
                            ? _c("div", { staticClass: "mt-2" }, [
                                _c("div", [
                                  _vm._v(
                                    ' Select the feature services to create from the "' +
                                      _vm._s(item.title) +
                                      '" feature layer. '
                                  ),
                                ]),
                                _vm.computedSelectedServices.length > 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "font-weight-bold",
                                          attrs: {
                                            label: "Select All",
                                            "hide-details": "",
                                          },
                                          on: { click: _vm.selectAllServices },
                                          model: {
                                            value: _vm.selectAll,
                                            callback: function ($$v) {
                                              _vm.selectAll = $$v
                                            },
                                            expression: "selectAll",
                                          },
                                        }),
                                        _c("v-divider", {
                                          staticClass: "mt-4",
                                        }),
                                        _vm._l(
                                          _vm.computedSelectedServices,
                                          function (service) {
                                            return _c("v-checkbox", {
                                              key: service.id,
                                              attrs: {
                                                label: service.name,
                                                "hide-details": "",
                                              },
                                              model: {
                                                value: service.create,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    service,
                                                    "create",
                                                    $$v
                                                  )
                                                },
                                                expression: "service.create",
                                              },
                                            })
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm.gettingFeatureServices
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            color: "primary",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "mt-2 font-weight-medium",
                                      },
                                      [
                                        _vm._v(
                                          " No services available for import "
                                        ),
                                      ]
                                    ),
                              ])
                            : _c("div", { staticClass: "mt-2" }, [
                                _vm._v(
                                  ' Import the "' +
                                    _vm._s(item.title) +
                                    '" map service? '
                                ),
                              ]),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      _vm.selectedServices.length === 0 ||
                                      _vm.disableImport,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.importSelectedServices()
                                    },
                                  },
                                },
                                [_vm._v("Import")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center pt-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.nextStart === -1 || _vm.loading,
                        color: "primary",
                      },
                      on: { click: _vm.getArcGISServices },
                    },
                    [_vm._v("Load More")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }